import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportPurchaseDetails } from "./import-purchase-details.component";
import { ConfirmationPopupModule } from "../confirmation-popup/confirmation-popup.module";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopupModule.forRoot()
  ],
  declarations: [ImportPurchaseDetails],
  exports: [ImportPurchaseDetails]
})
export class ImportPurchaseDetailsModule {
  static forRoot(): ModuleWithProviders<ImportPurchaseDetailsModule> {
    return {
      ngModule: ImportPurchaseDetailsModule,
    };
  }
}
