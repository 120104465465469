import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCustomerDialogComponents } from './create-customer-dialog.component';
import { NgMultiSelectDropDownModule  } from 'ng-multiselect-dropdown';
import { ConfirmationPopupModule } from "../confirmation-popup/confirmation-popup.module";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ConfirmationPopupModule.forRoot()
    
  ],
  declarations: [CreateCustomerDialogComponents],
  exports: [CreateCustomerDialogComponents]
})
export class CreateCustomerProfileModule {
  static forRoot(): ModuleWithProviders<CreateCustomerProfileModule> {
    return {
      ngModule: CreateCustomerProfileModule,
    };
  }
}
