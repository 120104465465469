import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { TAcList } from "../../interfaces/Account.interface";
import { MasterRepo } from '@app/_services/masterRepo.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { VoucherTypeEnum } from '@app/common/interfaces/TrnMain';
import { ConfirmationPopup } from '../confirmation-popup/confirmation-popup.component';


@Component({
    selector: 'quick-party-master',
    templateUrl: './quick-party-master.component.html',
    styles: [`
      input,
      select,
      textarea {
        border: 1px solid #cbcbcb;
        border-radius: 3px;
        height: 23px;
        color: black;
      }
    `]
})

export class QuickPartyMasterComponent implements OnInit{

    public mode: string;
    PartyGroupPartyMaster: any[] = [];
    GeoList: any[] = [];
    areaList:any[]=[];
    partymasterObj: any = <any>{
        ACID : '',
        ACCODE: '',
        ACNAME: '',
        ADDRESS:'',
        VATNO:'',
        GEO:'',
        CRLIMIT:'',
        CRPERIOD:'',
        PType:'',
        MOBILE:''
      }
      newAccountDetails:any[] = [];
      createMember:any;
      isActive:boolean;
      @Output('onSaveParty') onSaveParty = new EventEmitter();
      @ViewChild('partyGroup') partyGroup: ElementRef;
      @ViewChild('ProceedConfirmation') ProceedConfirmation:ConfirmationPopup;
  DialogMessage: string;


    constructor(public masterService: MasterRepo,
      private alertService: AlertService,
      public _trnMainService: TransactionService,
      public spinnerService:SpinnerService
      ){

        this.partymasterObj.CRLIMIT = 0;
        this.partymasterObj.CRPERIOD = 0;
        
        

        if(this._trnMainService.TrnMainObj.VoucherPrefix == 'TI'){
          this.partymasterObj.PType = 'C';
        }
        if(this._trnMainService.TrnMainObj.VoucherPrefix == 'PI'){
          this.partymasterObj.PType = 'V';
        }

        // to get area list.
      this.masterService.getAreaDetail().subscribe( res =>{
            this.areaList = res.result;
      });

      // to get party group
      this.masterService.getPartyGroupForQuickPartyMaster().subscribe(res => {
        this.PartyGroupPartyMaster = res.result;
    });

// to get geolist
    this.masterService.getGeoList().subscribe(res => {
      if (res.status == "ok") {
          this.GeoList = res.result;
      }
     });

     if(this.masterService.userSetting.CREATE_CPROFILE_AS_MEMBER == 1 && this.partymasterObj.PType == 'C'){
      this.createMember=true;
    }

    }

    


    ngOnInit(){


    }

    

    resetPartyAccount(){
      this.partymasterObj.ACID='';
      this.partymasterObj.ACCODE='';
      this.partymasterObj.ACNAME='';
      this.partymasterObj.ADDRESS='';
      this.partymasterObj.VATNO='';
      this.partymasterObj.GEO='';
      this.partymasterObj.CITY='';
      this.partymasterObj.CRLIMIT= 0 ;
      this.partymasterObj.CRPERIOD= 0 ;
      this.partymasterObj.MOBILE='';
      if(this.masterService.isSalesPage == true){
          this.partymasterObj.PType = 'C';
        }else{
          this.partymasterObj.PType = 'V';
        }
    }


    savePartyMaster(){
      // if(this._trnMainService.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase){

      // }
    
  
      let savePartyMaster = Object.assign(<TAcList>{}, this.partymasterObj);

        savePartyMaster.PARENT = this.partymasterObj.ACID;
        savePartyMaster.CRLIMIT = this.partymasterObj.CRLIMIT ? this.partymasterObj.CRLIMIT : 0;
        savePartyMaster.CRPERIOD = this.partymasterObj.CRPERIOD ? this.partymasterObj.CRPERIOD : 0;
        savePartyMaster.CATEGORY=this.partymasterObj.GEO;
        savePartyMaster.ISACTIVE = 1;
        savePartyMaster.PCL = 'pc002';
        if(this.partymasterObj.CITY !=null){
          let checkAreaID = this.areaList.filter (x=> x.AREANAME.toUpperCase() == this.partymasterObj.CITY.toUpperCase());
          
          savePartyMaster.AREA_ID=checkAreaID[0]?checkAreaID[0].AREAID:null;
         }
        if (savePartyMaster.ACID == '') {
          alert("Please Select Party Group First!")
          return;
        }
        if (savePartyMaster.ACNAME == '') {
          alert("Please Enter Party Name First!")
          return;
        }
        if (savePartyMaster.ADDRESS == '') {
          alert("Please Enter Address First!")
          return;
        }

        if(this._trnMainService.TrnMainObj.VoucherPrefix == 'TI'){
          this.partymasterObj.PType='C';
          savePartyMaster.PType='C';
        }else if(this._trnMainService.TrnMainObj.VoucherPrefix == 'PI'){
          this.partymasterObj.PType='V';
          savePartyMaster.PType='V';
        }

        if(savePartyMaster.PType=='V'){
          if(savePartyMaster.VATNO == ''){
            if(confirm("VAT NO. is empty!! Are you sure you want to continue ?")){

            }else{
              return;
            }
            // alert("Please Enter VATNO First!")
            // return;
          }
        }
        if(savePartyMaster.VATNO!=''){
        if(savePartyMaster.VATNO.length!=9){
            alert("VAT NO should be nine digit length!")
            return;
        }
        }
        
        
        if(this.masterService.userSetting.CREATE_CPROFILE_AS_MEMBER == 1 && this.createMember==true && savePartyMaster.PType=='C'){
          if (savePartyMaster.MOBILE == '') {
            alert("Please Enter Mobile No. First!")
            return;
          }
        }

        if(savePartyMaster.MOBILE!=''){
          if(savePartyMaster.MOBILE.length!=10){
              alert("Mobile No. should be nine digit length!")
              return;
          }
          }
      
          if(this.partymasterObj.PType=='V'){
            this.createMember=false;
          }
          
        this.mode = "add";
        this.spinnerService.show("Data is saving Please Wait..");
        console.log("save party master", savePartyMaster);
       let sub = this.masterService.savePartyMaster(
        this.mode,
        savePartyMaster,
        this.createMember

    ).subscribe(
      data => {
        if (data['status'] == "ok") {

           if(data['result']=="ProceedConfirmation"){
            
            this.DialogMessage = "Member is not Creadted on server.Do you want to Proced ";
            this.ProceedConfirmation.show(this.DialogMessage);
            //  if(confirm("Member is not Creadted on server.Do you want to Proced")){
            //  // savePartyMaster.FurtherProceed = "ProceedConfirmationYes";
              
            //   this.createMember = false;
            //   this.savePartyMaster()
            //  }else{
            //   this.spinnerService.hide();
            //  }
           }else{
            this.cancelPartyPopup();
            this.alertService.success("Data Saved Successfully");
            this.spinnerService.hide();
            setTimeout(()=>{
              this.alertService.hide();
            },500);
            this.onSaveParty.emit(data['result2']);
           }     
          } 
          else {
          this.spinnerService.hide();
          this.alertService.error("Error in Saving Data:" + data);
        }
      },
        error => {
          this.alertService.error(error.error);
          this.spinnerService.hide();
        }
        );
  
  }

  onProceedConfirmationClicked(event){
    if(event == true){
      this.createMember = false;
      this.savePartyMaster()
     }else{
      this.spinnerService.hide();
     }

  }


    cancelPartyPopup() {
        this.isActive=false;
        this.resetPartyAccount();
    }

    keyPress(event: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
        if (!pattern.test(inputChar)) {    
            // invalid character, prevent input
            event.preventDefault();
        }
      }

      onChangeArea(event: any){
        let userAreaName = event.target.value;
          let checkArea = [];   
          if(userAreaName !=null){
              checkArea = this.areaList.filter (x=> x.AREANAME.toUpperCase() == userAreaName.toUpperCase());
              if(checkArea.length ==0){
                if(confirm("Do you want to add new Area")){
                  }else{
                  }
              }   
          }
      }

      show(){
        this.isActive=true;
        setTimeout(() => {
          this.partyGroup.nativeElement.focus();
        }, 10);
      }

      hide(){
        this.isActive=false;
      }
 
    

}