<div class="modal-content" style="width: 420px;" *ngIf="isConfirmationVisible">
    <div class="modal-header">
      <h4 class="modal-title"  style="color: rgb(255, 255, 255)">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"
        style="color: rgb(255, 255, 255)"></i>
        Confirmation</h4>
      <button class="close" aria-label="Close" (click)="close(null)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12" style="margin-top: 15px;margin-bottom: 15px;">
            {{this.DialogMessage}}
        </div>
        <div class="col-md-12">
          <button  style="margin-right: 4px;" class="btn btn-info btn-sm" (click)="onSubmit()">Yes</button>
          <button  style="background-color: #e9717d; font-size: 12px;" class="btn btn-danger btn-sm" (click)="close(null)">No</button>
        </div>
      </div>
    </div>
</div>       
  