
import { Injectable, Provider } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/_helperServices/authService.service';
import { SettingService } from '@app/_helperServices/setting.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TAcList } from '../interfaces/Account.interface';
import { IDivision } from '../interfaces/commonInterface.interface';
import { Filter } from '../interfaces/filter.interface';
import { PREFIX } from '../interfaces/Prefix.interface';
import { Product, AltSellingPrice } from '../interfaces/ProductItem';
import { Transporter_Eway, TrnMain, TrnMain_AdditionalInfo, TrnProd, Trntran, VoucherTypeEnum, AlternateUnit, IMPORT_DETAILS } from '../interfaces/TrnMain';
import { AlertService } from '../shared/alert/alert.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import * as uuid from 'uuid';
import { PriceRate } from '../interfaces/PriceRate.interface';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { BarCodeInterface } from '../interfaces/BarCodeInsertInterface';
import { PreventNavigationService } from '../services/navigation-perventor/navigation-perventor.service';
import { Alert } from 'selenium-webdriver';
import { Console } from 'console';
import _ from 'lodash';
import { nullSafeIsEquivalent, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlternateUnitTabComponent } from '@app/pages/masters/components/ProductMaster/AlternateUnitTabComponent';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
@Injectable()
export class TransactionService {
  TOTALALLDISCOUNT: number;
  TrnMainObj: TrnMain = <TrnMain>{};
  FilterObj: Filter = <Filter>{};
  Warehouse: string;
  pageHeading: string;
  buttonMode: string;
  prefix: PREFIX = <PREFIX>{};
  division: IDivision = <IDivision>{};
  saveDisable: boolean;
  differenceAmount: number = 0;
  trntranTotal: number = 0;
  CustomerObjForDiscount: any = <any>{};
  activeurlpath: any;
  crTotal: number = 0;
  drTotal: number = 0;
  salesMode: string;
  cnMode: string;
  warrentyUpToDate: Date;
  warrentyVchrList: any[];
  buttonHeading: string = 'Reference No';
  QuantityMsg: string;
  piceDuplicateMessage: string;
  inputCode: boolean;
  qtyCheckForPrint: boolean = false;
  customerEvent: boolean = false;
  printModeForInvoice: any;
  prodListForUnfinedBill: boolean = false;
  mulitplePriceItemList = [];
  memberPopupForRefer: boolean = false;
  saveChanges: boolean = true;
  userToken:any;
  accountListSubject: BehaviorSubject<TAcList[]> = new BehaviorSubject<
    TAcList[]
  >([]);
  accountListObersver$: Observable<
    TAcList[]
  > = this.accountListSubject.asObservable();
  TableAcHeader: string = 'Description';
  prodListMode: ProdListMode;
  prodDisableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  prodDisable$: Observable<boolean> = this.prodDisableSubject.asObservable();
  cnReturnedProdList: TrnProd[];
  referenceNoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  referenceNo$: Observable<string> = this.referenceNoSubject.asObservable();
  public PrintStuffSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public PrintStuff$: Observable<any> = this.PrintStuffSubject.asObservable();
  currentIndexProductDetails = [];

  supplierwiseItem: any = 0;
  returnVoucherMain: any = <any>{};
  DefaultSellableWarehouse: string;
  partyList: any[] = [];
  userProfile: any = <any>{};
  CustomerList: any[] = [];
  doubleClick: boolean = false;
  customerListInPopup: boolean = false;
  salesmanID: string;
  salesmanName: string;
  routeCode: string;
  routename:string;
  customerDoubleClick: boolean = false;
  saveButton: boolean = false;
  billtoStatus: boolean = false;
  billunitevent: boolean = false;
  // extra settings for performa invoice
  showPerformaApproveReject: boolean = false; // initially false
  disableProd: boolean = false;
  disableAllFieldinPurchase = false;
  disableAllFieldinSales = false;
  quantityExceedCheck:string="";  
  reverseViewTag:string="";  

  fromNotification: boolean = false;
  billTo: string;
  BarcodeFromScan: string;
  ItemsListForMultiItemBarcode: any[] = [];
  voucherNoHeader: string = 'Bill No';
  availableSchemes: any[] = [];
  duplicateBarCodeMessage: string;
  public barCodes:BarCodeInterface=<BarCodeInterface>{};

  showBarCodePopUPinViewMode: boolean = false;
  showBarCodePopUPSubject: Subject<any> = new Subject<any>();
  barcodeObj$: Observable<any> = this.showBarCodePopUPSubject.asObservable();
  hideProductDescriptionPopUp: boolean = false;
  userSetting: any;
  showPriceChangeDetail: boolean = false;
  showPriceChangeDetailINDisc: boolean = false;
  selectedProdcutRecMargin: any;
  priceChangeList: any = [];
  showBatchWiseQtyEntryPopup: String;
  static ReportMainService: Provider;
  ReturnStockObj: any = <any>{};
  urlpath: any = '';
  itemselectevent: boolean = false;
    public purchaseOrderQty:number;
    public purchaseOrderRate:number;
    public produstWiseOrderUnit:string;
  showDiscountAuth: boolean=false;
  disableOnApproval:boolean = false;
  disableOnGRNload: boolean = false;
  productionEntryPrefix: string = 'PD'
  disableNepaliDate: boolean = false;
  disableRateOnPOselect: boolean = false;
  disableSupplierInfo: boolean = false;
  disableCustomerInfo: boolean = false;
  checkWhileSave: boolean = false;
  itemname: string;
  disableOnPerFormaSelect: boolean = false;
  onSoLoad: boolean = false;
  disableRateOnGRNload: boolean = false;
  TempTrnMainObj: TrnMain = <TrnMain>{};
  disablePaymentTerms: boolean = false;
  checkBatchWiseApiResponses:boolean = false;
  salesReturnWarehouse: any;
  warehousetypeforSalesReturn: string;
  disableOnNotificationLoad: boolean =false;
reverseTax:boolean=false;
  freeList: any[];
  warehouseId:boolean= false;
  disableFlatPer:boolean=false;
  disableFlatAmnt:boolean=false;
  Dup_ItemBatchValidation: string;
  schemeApplyClick:boolean = false;
  itemrowcount=0;
  DMSCOMPANY:string="";
  submitFreeStock:boolean=false;
  schemeApply:any[]=[];
  schemePresentCheck: boolean = false;
  constructor(
    private masterService: MasterRepo,
    private setting: SettingService,
    private authservice: AuthService,
    private alertService: AlertService,
    private loadingService: SpinnerService,
    private arouter: ActivatedRoute,
    private router: Router,
    public preventNavigationService: PreventNavigationService,
    // public freeQtyCalculation:FreeQtyCalculation
  ) {
    this.AppSettings = setting.appSetting;
    this.userProfile = authservice.getUserProfile();
    this.userSetting = authservice.getSetting();
    this.userToken=authservice.getToken();
    this.DMSCOMPANY=this.masterService.userSetting.COMPANYNAME;
    // this.masterService.getProductItemList().subscribe();

     this.activeurlpath = arouter.snapshot?.url[0]?.path;
    this.TrnMainObj.AdditionalObj = {} as TrnMain_AdditionalInfo;

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        let url = ev.url.split("/");
        this.urlpath = url[url.length - 1];
      }
    });

    this.masterService.getVariantConfiguration();

  }

  trnmainBehavior: BehaviorSubject<TrnMain> = new BehaviorSubject(<TrnMain>{});
  loadDataObservable: Observable<TrnMain> = this.trnmainBehavior.asObservable();
  PMode: string; // p for party mode and c for customer mode
  MaxTotalAmountLimit: number = 5000;
  DefaultCustomerAc: string = '';
  SettlementNo: any;
  INVMAIN: any;
  AppSettings: any;
  CashList: any[] = [];
  PurchaseAcList: any[] = [];
  paymentmodelist: any[] = [];
  paymentAccountList: any[] = [];
  settlementList: any[] = [];
  loadData(VCHR, division, phiscalid) {
      console.log("mode",this.buttonMode);
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid,'','','',this.buttonMode).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          console.log("LOAD DATA", this.TrnMainObj);
          
          this.TrnMainObj.Mode = this.buttonMode;
          this.TrnMainObj.GROSSAMNT = (this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase && this.TrnMainObj.VATBILL == 1) ? data['result'].NONTAXABLE : data['result'].TAXABLE;
if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase ){
  if( this.TrnMainObj.VATBILL == 1){
  this.TrnMainObj.GROSSAMNT =data['result'].NONTAXABLE ;
}else{
  this.TrnMainObj.GROSSAMNT =data['result'].TOTAMNT ;
}}else{
  this.TrnMainObj.GROSSAMNT =data['result'].TOTAMNT ;
}
          this.TrnMainObj.TOTALQTY = 0;
          data['result'].ProdList.forEach(x => this.TrnMainObj.TOTALQTY += x.Quantity);

          this.TrnMainObj.TOTALSQTY = 0;
          data['result'].ProdList.forEach(x=> this.TrnMainObj.TOTALSQTY += x.Quantity);

          if (this.urlpath == "purchase-import") {
            this.TrnMainObj.CNDN_MODE = 10;
            this.pageHeading = "Import Purchase";
            this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE";
          }else if(this.urlpath == "transfer-out-approval" || this.urlpath =="stock-issue-approval"){
            this.TrnMainObj.Mode = "NEW";
          }else if (this.urlpath == "purchase-import-order") {
            this.TrnMainObj.CNDN_MODE = 10;
            this.pageHeading = "Import Purchase Order";
            this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE_ORDER";
          }else if(this.urlpath == "performa-invoice"){
         
          }
          if (this.TrnMainObj.CHALANNOPREFIX != null && this.TrnMainObj.CHALANNOPREFIX !== '') {
            if (this.TrnMainObj.CHALANNO != null || this.TrnMainObj.CHALANNO !== '') {
              let prefixFromChalan = this.TrnMainObj.CHALANNO.substring(0, 3);
              if (prefixFromChalan != null) {
                if (this.TrnMainObj.CHALANNOPREFIX.toUpperCase() === prefixFromChalan.toUpperCase()) {
                  this.TrnMainObj.CHALANNO = this.TrnMainObj.CHALANNO.substring(3);
                }
              }
            } else {
              this.alertService.info('Chalan No Could not be Null');
            }
          }
          if (this.TrnMainObj.TransporterEway == null) {
            this.TrnMainObj.TransporterEway = <any>{};
          }
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
            this.TrnMainObj.Mode = 'EDIT';
            this.TrnMainObj.ProdList.forEach(element => {
              element.inputMode = true;
            });
          }

          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
            if (this.TrnMainObj.AdditionalObj.INVNO != null
              && this.TrnMainObj.AdditionalObj.INVNO != undefined && this.TrnMainObj.AdditionalObj.INVNO != "") {
              this.disableAllFieldinPurchase = true;
              this.disableAllFieldinSales = true;
              this.alertService.info("This voucher can not be Edit");
            }
          }

  
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.CNDN_MODE === 10){
            this.TrnMainObj.AdditionalObj.tag = 'IMPORT_PURCHASE_ORDER';
          }


          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.activeurlpath != 'purchase-import-order'){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
            this.TrnMainObj.TAXABLE = this.TrnMainObj.GROSSAMNT;
            this.TrnMainObj.NETWITHOUTROUNDOFF = this.TrnMainObj.TOTAMNT + this.TrnMainObj.VATAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
            if(this.TrnMainObj.VATAMNT != 0 ){
              this.TrnMainObj.TAXABLE = this.TrnMainObj.GROSSAMNT;

            }
            this.TrnMainObj.NETWITHOUTROUNDOFF = this.TrnMainObj.NETAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry || this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.NETAMNT;
          }

          if(this.TrnMainObj.VoucherType == VoucherTypeEnum.StockIssue && this.activeurlpath=='add-stock-issue'){
            this.TrnMainObj.BILLTOADD = this.TrnMainObj.SETTLEMENTMODE;
          }
          // if(this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived && this.TrnMainObj.REFORDBILL && this.userSetting.PODELIVERYMODE !== 0){
          //   this.TrnMainObj.ProdList.forEach( x => {
          //     x.POSTOCK = x.ALTERNATEQUANTIY;
          //   });
          // }
          this.TrnMainObj.CUS_Remote_Discount=this.TrnMainObj.AdditionalObj.TOTDiscountPercentage;
          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            // this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            // this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            if(this.TrnMainObj.ProdList[i].MFGDATE != null){
              this.TrnMainObj.ProdList[i].MFGDATE = this.userSetting.enableDayWiseMfgEXPDate == 0? this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0,7) :this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0,10);
            }else{
              this.TrnMainObj.ProdList[i].MFGDATE = '';
            }
            if(this.TrnMainObj.ProdList[i].EXPDATE != null){
              this.TrnMainObj.ProdList[i].EXPDATE = this.userSetting.enableDayWiseMfgEXPDate == 0? this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0,7) :this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0,10);
            }else{
              this.TrnMainObj.ProdList[i].EXPDATE = '';
            }
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;

            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;

            if(this.TrnMainObj.Mode == 'DRILL'){
              if(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY) ==0){
                this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY = this.TrnMainObj.ProdList[i].AltQty;
              }
            }

            if(this.TrnMainObj.VoucherType == VoucherTypeEnum.OpeningStockBalance){
                this.TrnMainObj.ProdList[i].SPRICE_WITHVAT = this.nullToZeroConverter(this.TrnMainObj.ProdList[i].SPRICE)*1.13;
            }
      
          }

          for(let i in this.TrnMainObj.ProdList){

            this.TrnMainObj.ProdList[i].VARIANTDESCA = "";
            if(this.TrnMainObj.ProdList[i].HASVARIANT == 1){
              // console.log("VARAINT LIST IN LOAD", this.TrnMainObj.ProdList[i].VARIANTLIST);
              for (var attribute in this.TrnMainObj.ProdList[i].VARIANTLIST) {
                if (['QTY', 'PRATE', 'MRP', 'SRATE','BARCODE'].indexOf(attribute) == -1) {
                  this.TrnMainObj.ProdList[i].VARIANTDESCA = this.TrnMainObj.ProdList[i].VARIANTDESCA + `<b>${this.getVariantNameFromId(attribute)}</b>:${this.TrnMainObj.ProdList[i].VARIANTLIST[attribute].NAME} &nbsp;`
                }
              }
              // console.log("on eidt",this.TrnMainObj.ProdList[i].VARIANTDESCA)
            }
          }

          this.Warehouse = this.TrnMainObj.MWAREHOUSE;
          if (
            !this.Warehouse &&
            this.TrnMainObj.ProdList &&
            this.TrnMainObj.ProdList.length > 0
          ) {
            this.Warehouse = this.TrnMainObj.ProdList[0].WAREHOUSE;
          }
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
          this.TrnMainObj.CHEQUEDATE =
            this.TrnMainObj.CHEQUEDATE == null
              ? ''
              : this.TrnMainObj.CHEQUEDATE.toString().substring(0, 10);
          this.TrnMainObj.DeliveryDate =
            this.TrnMainObj.DeliveryDate == null
              ? ''
              : this.TrnMainObj.DeliveryDate.toString().substring(0, 10);
            this.TrnMainObj.ORDEXPDATE = 
              this.TrnMainObj.ORDEXPDATE == null
              ?''
              :this.TrnMainObj.ORDEXPDATE.toString().substring(0,10);

          this.TrnMainObj.TrntranList.forEach(trntran => {
            if (trntran.A_ACID) {
              trntran.acitem = trntran.AccountItem;
              trntran.ROWMODE === 'save';
            } else {
              trntran.acitem = <any>{};
              trntran.ROWMODE === 'save';
            }
          });

       

        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }
  onApplyClicked(event) {
    this.disableProd = true;
    this.disableFlatAmnt=true;
    console.log("r1",event.target.value);
    this.TrnMainObj.DCRATE=event.target.value;
    if (this.nullToZeroConverter(this.TrnMainObj.DCRATE) !== 0) {
      let disLimit = this.masterService.userProfile.roleRights.find(x=> x.right === 'DISLIMIT');
      if(disLimit){
        if(this.nullToZeroConverter(this.TrnMainObj.DCRATE)> disLimit.value){
          if(confirm("Sorry, Discount Limit Exceeded. Please, Ask for it's Authorization?")){
            this.showDiscountAuth = true;
      
          }
          return;
        }
      }
     
      
    }else{
        if(this.TrnMainObj.DCRATE==0||this.TrnMainObj.DCRATE==null||this.TrnMainObj.DOMANUALSALESRETURN ==1){
          this.TrnMainObj.FLATDCAMNT=0;
          this.disableFlatAmnt=false;
          this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = this.TrnMainObj.DCRATE)
          this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNT = 0)
        }
      }
      this.FlatDiscountPercentChange();
     
  }
   onAppliedFlatamt(event){
     this.disableFlatPer=true;
     this.TrnMainObj.FLATDCAMNT=event.target.value;
    if(this.TrnMainObj.Mode!="VIEW"){
      this.TrnMainObj.ProdList.forEach(x =>{ x.INDDISCOUNT=0, x.DISCOUNT=0});  
       console.log('pathd',this.activeurlpath);
      if(this.activeurlpath === 'abbreviated-sales-invoice' || ( this.userSetting.EnableInDisCalculationInTi == 1 && this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice  ) ){        
        this.calculateInclusiveDiscout(this.TrnMainObj.FLATDCAMNT);
        this.ReCalculateBillWithNormal();
      }else{
        if(this.TrnMainObj.FLATDCAMNT==0||this.TrnMainObj.FLATDCAMNT==null||this.TrnMainObj.DOMANUALSALESRETURN ==1){
          this.TrnMainObj.DCRATE=0;
          this.disableFlatPer=false;

        }
        this.FlatDiscountAmountChange();
      }
      
    }
   
    //  this.TrnMainObj.FLATDCAMNT=Number(this.nullToZeroConverter(this.TrnMainObj.TAXABLE).toFixed(2));
  
   }

  calculateInclusiveDiscout(discountAmount:number):void{
    if(discountAmount){
      
      let rawTaxable = this.TrnMainObj.ProdList.filter(x => x.ISVAT ==1).map(x =>x.AMOUNT).reduce((sum,initail)=> sum+initail,0);
      let rawNonTaxable = this.TrnMainObj.ProdList.filter(x => x.ISVAT ==0).map(x =>x.AMOUNT).reduce((sum,initail)=>sum+initail,0);

      this.TrnMainObj.ProdList.forEach(x =>  { 
        if(x.ISVAT ==1){
          x.INDDISCOUNT = ((x.AMOUNT)/(rawTaxable+rawNonTaxable))* (discountAmount/this.userSetting.VatConRate);
        }else{
          x.INDDISCOUNT = (x.AMOUNT/(rawNonTaxable+rawTaxable))* discountAmount;
        }
      }
      ) 
    }        
  }

  onRevertClicked() {
    this.disableProd = true;
    this.TrnMainObj.DCRATE = 0;
    this.TrnMainObj.FLATDCAMNT = 0;
    this.TrnMainObj.TOTALFLATDISCOUNT = 0;
    this.TrnMainObj.ProdList.forEach(x => { x.INDDISCOUNT = 0, x.FLATDISCOUNT = 0, x.INDDISCOUNTRATE = 0, x.CRATE = x.RATE });
    this.ReCalculateBillWithNormal();

  }


  loadStockSettlement(VCHR) {
    this.masterService.LoadStockSettlement(VCHR).subscribe(
      data => {
        if (data.status === 'ok') {
          this.TrnMainObj = data.result;
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
            this.TrnMainObj.Mode = 'NEW';
            this.TrnMainObj.ProdList.forEach(element => {
              element.inputMode = true;
            });
          }

          for (let i in this.TrnMainObj.ProdList){
            //this.setAltunitDropDownForViewStock(i);
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].IsApproveStockSettlement = true;
            this.TrnMainObj.ProdList[i].MFGDATE =
              this.TrnMainObj.ProdList[i].MFGDATE == null
                ? ''
                : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(
                  0,
                  10
                );
            this.TrnMainObj.ProdList[i].EXPDATE =
              this.TrnMainObj.ProdList[i].EXPDATE == null
                ? ''
                : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(
                  0,
                  10
                );
          }
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.Warehouse = this.TrnMainObj.MWAREHOUSE;
          if (
            !this.Warehouse &&
            this.TrnMainObj.ProdList &&
            this.TrnMainObj.ProdList.length > 0
          ) {
            this.Warehouse = this.TrnMainObj.ProdList[0].WAREHOUSE;
          }
          this.trnmainBehavior.next(this.TrnMainObj);
        }
      },
      error => {
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => this.trnmainBehavior.complete()
    );
  }
  

  getItemType() {
    return [
      { label: 'FOC', value: 'FOC' },
      { label: 'FAULTY', value: 'FALUTY' }
    ];
  }

  getReceivedType() {
    return [
      { label: 'DAMAGED', value: 'DAMAGED' },
      { label: 'MISSING', value: 'MISSING' }
    ];
  }

  nullToZeroConverter(value) {

    if (
      value === undefined ||
      value == null ||
      value === null ||
      value === '' ||
      value === 'Infinity' ||
      value === 'NaN' ||
      value === NaN ||
      isNaN(parseFloat(value))
    ) {
      return 0;
    }
    return parseFloat(value);
  }

  CalculateNormal(
    TrnProdObj: TrnProd,
    ServiceTaxRate = this.setting.appSetting.ServiceTaxRate,
    VatRate = this.setting.appSetting.VATRate,
    calcuteDiscount = 0
  ) {
    TrnProdObj.AMOUNT = TrnProdObj.Quantity * TrnProdObj.RATE;
    if (this.setting.appSetting.ENABLEMULTICURRENCY === 1) {
      TrnProdObj.NCRATE = this.curencyConvert(TrnProdObj.RATE);
      TrnProdObj.EXRATE = this.TrnMainObj.EXRATE;
      TrnProdObj.AMOUNT = TrnProdObj.Quantity * TrnProdObj.NCRATE;
    }
    if (calcuteDiscount === 1) {
      if (TrnProdObj.IDIS != null) {
        if (TrnProdObj.IDIS.indexOf('%') < 0) {
          TrnProdObj.INDDISCOUNT = parseFloat(TrnProdObj.IDIS);
        } else {
          let dis = parseFloat(TrnProdObj.IDIS.replace('%', '').trim());
          dis = dis / 100;
          TrnProdObj.INDDISCOUNT = TrnProdObj.AMOUNT * dis;
        }
      }
    }
    TrnProdObj.DISCOUNT =
      this.nullToZeroConverter(TrnProdObj.INDDISCOUNT) +
      this.nullToZeroConverter(TrnProdObj.PROMOTION) +
      this.nullToZeroConverter(TrnProdObj.LOYALTY);
    if (this.nullToZeroConverter(TrnProdObj.ISSERVICECHARGE) === 1) {
      TrnProdObj.SERVICETAX =
        (TrnProdObj.AMOUNT - TrnProdObj.DISCOUNT) * ServiceTaxRate;
    }
    if (TrnProdObj.ISVAT === 1) {
      TrnProdObj.TAXABLE =
        TrnProdObj.AMOUNT -
        TrnProdObj.DISCOUNT +
        this.nullToZeroConverter(TrnProdObj.SERVICETAX);
      TrnProdObj.NONTAXABLE = 0;
      TrnProdObj.VAT = TrnProdObj.TAXABLE * VatRate;
      TrnProdObj.GSTRATE = VatRate;
    } else {
      TrnProdObj.TAXABLE = 0;
      TrnProdObj.NONTAXABLE =
        TrnProdObj.AMOUNT -
        TrnProdObj.DISCOUNT +
        this.nullToZeroConverter(TrnProdObj.SERVICETAX);
    }
    TrnProdObj.NETAMOUNT =
      TrnProdObj.TAXABLE + TrnProdObj.NONTAXABLE + TrnProdObj.VAT;
    return TrnProdObj;
  }
  FlatDiscountPercentChange() {
    
    this.TrnMainObj.INDDIS = 0; // IF Discount is given in percentage
    //this.TrnMainObj.FLATDCAMNT=0; //if disamount is changed then
    let checkIndDiscout = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.INDDISCOUNTRATE) > 0 || this.nullToZeroConverter(x.INDDISCOUNT) > 0)[0];
    if (checkIndDiscout != null) {
      if (confirm("Are you sure you want ko give individual discount")) {
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales||this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote
        ) {
          this.TrnMainObj.ProdList.forEach(x => { x.INDDISCOUNTRATE = this.nullToZeroConverter(this.TrnMainObj.DCRATE), x.CRATE = (x.RATE - (x.RATE * this.nullToZeroConverter(this.TrnMainObj.DCRATE) / 100)) });
              //flatdiscount amout calc from flat dis percent
          this.ReCalculateBillWithNormal();
          this.flatDiscAmountCalculation();
          
        } else {
          this.flatDiscountAssign('percent');
        }
      }
    } else {
      if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales||this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote
      ) {
        this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = this.TrnMainObj.DCRATE)
  
        this.ReCalculateBillWithNormal();
       this.flatDiscAmountCalculation();
         
      } else {
        this.flatDiscountAssign('percent');
      }
    }

  }


  calculateNetRATE(i) {

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
      (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")

    ) {

      if (this.TrnMainObj.ProdList[i].ALTUNIT == this.TrnMainObj.ProdList[i].BASEUNIT) {

        this.TrnMainObj.ProdList[i].NETRATE =
          ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY)) -
            ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY)) *
              this.nullToZeroConverter(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].INDDISCOUNTRATE) / 100)));

      } else {
        this.TrnMainObj.ProdList[i].NETRATE =
          ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[i].ALTCONFACTOR))) -
            ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[i].ALTCONFACTOR))) *
              this.nullToZeroConverter(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].INDDISCOUNTRATE) / 100)));

      }
      this.TrnMainObj.ProdList[i].CRATE = this.TrnMainObj.ProdList[i].NETRATE;

    }
  }

  FlatDiscountAmountChange() {
//if disamount is changed then
    let checkIndDiscout = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.INDDISCOUNTRATE) > 0 || this.nullToZeroConverter(x.INDDISCOUNT) > 0)[0];
    console.log("flat dicosunt amount chnage", checkIndDiscout);
    if (checkIndDiscout != null) {
      if (confirm("Are you sure you want to give individual discount")) {
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
        ) {
          this.TrnMainObj.ProdList.forEach(x => {
            x.INDDISCOUNT = 0;
            x.INDDISCOUNTRATE = 0;        
          })
       
          this.ReCalculateBillWithNormal();
          this.flatDiscRateCalculation();
        } 
        else if(this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice 
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice 
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales){
            this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = this.TrnMainObj.DCRATE)
         this.ReCalculateBillWithNormal()
         this.flatDiscRateCalculation();
         // this.calculateFixedDiscount();
    
        }
        else {
          this.flatDiscountAssign('amount');
        }
       
      }
    } else {
      if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder  || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder)  {
      
          let totalGrossAmount =0;
          this.TrnMainObj.ProdList.forEach(x => totalGrossAmount += x.AMOUNT);
          if(this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) > 0){
            this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = (this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) * 100) / totalGrossAmount );
            this.ReCalculateBillWithNormal();
            this.flatDiscRateCalculation();
          }
      }
      else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales) {
        this.flatDiscRateCalculation();
       //this.calculateFixedDiscount();
      }
      else {
        this.flatDiscountAssign('amount');
      }
    }
  }


  calculateFixedDiscount(){
    let grossAmount = 0;
    this.TrnMainObj.ProdList.filter(x => x.DISMODE != 'Non-Discountable').forEach(
      x => {
        grossAmount += x.AMOUNT;
      }
    )
    let flatDiscountPercent = (this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT) / (grossAmount / 100));
    this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = flatDiscountPercent);
    this.ReCalculateBillWithNormal();
  }


  calculateNetRateInFamount(){
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {

      for (const iterator of this.TrnMainObj.ProdList) {
              if (iterator.UNIT == iterator.BASEUNIT) {
        iterator.NETRATE =
          (iterator.RATE +
            (this.nullToZeroConverter(iterator.ECSAMOUNT) /
              iterator.Quantity) -
            this.nullToZeroConverter(iterator.INDDISCOUNT/iterator.Quantity) -  this.nullToZeroConverter(iterator.FLATDISCOUNT/iterator.Quantity)  );
        iterator.CRATE = iterator.NETRATE;

      } else {
        iterator.NETRATE =
          (iterator.RATE +
            (this.nullToZeroConverter(iterator.ECSAMOUNT) /
              iterator.Quantity) -
            this.nullToZeroConverter(iterator.INDDISCOUNT / iterator.Quantity) - this.nullToZeroConverter(iterator.FLATDISCOUNT/iterator.Quantity) );

        iterator.CRATE = iterator.NETRATE;
      }       

      }

    }

  }


  flatDiscountAssign(changeType) {
    if (
      changeType === 'percent' &&
      (this.TrnMainObj.DCRATE < 0 || this.TrnMainObj.DCRATE > 100)
    ) {
      this.TrnMainObj.DCRATE = 0;
      alert('Invalid Flat Discount Percent');
      return;
    }
    if (changeType === 'amount') {
      this.TrnMainObj.DCRATE = 0;
    } else {
      let totalAmountWithIndDiscount = this.TrnMainObj.ProdList.reduce(
        (sum, x) =>
          sum +
          (this.nullToZeroConverter(x.AMOUNT) -
            this.nullToZeroConverter(x.INDDISCOUNT)),
        0
      );
      this.TrnMainObj.TOTALFLATDISCOUNT =
        (this.TrnMainObj.DCRATE * totalAmountWithIndDiscount) / 100;
    }
    this.ReCalculateBillWithNormal();
  }

  curencyConvert(rate) {
    let C = this.masterService.Currencies.find(
      x => x.CURNAME === this.TrnMainObj.FCurrency
    );
    if (C != null) {
      return C.EXRATE * rate;
    } else return rate;
  }

  ischeckReturnQuantityError(prod: TrnProd): boolean {
    try {
      let pr = this.cnReturnedProdList.find(itm => itm.MCODE === prod.MCODE);
      if (!pr) {
        // there is no such product in return list
        return true;
      }
      let prodarray: TrnProd[] = [];
      this.cnReturnedProdList.forEach(prd => {
        let p: TrnProd;

        if (prodarray.length > 0) {
          p = prodarray.find(res => res.MCODE === prd.MCODE);
        }
        if (p) {
          p.Quantity += prd.Quantity;
          p.REALQTY_IN += prd.REALQTY_IN;
          if (prd.RATE > p.RATE) {
            p.RATE = prd.RATE;
          }
        } else {
          prodarray.push(prd);
        }
      });
      let tProd = prodarray.find(prd => prd.MCODE === prod.MCODE);
      if (tProd) {
        if (prod.Quantity > tProd.Quantity) {
          return true;
        }
        if (prod.RATE > tProd.RATE) {
          return true;
        }
      }

      return false;
    } catch (ex) {
    }
  }

  resetData() {
    this.TrnMainObj.TRNAC = null;
    this.TrnMainObj.PARAC = null;
    this.TrnMainObj.BILLTO = '';
    this.TrnMainObj.BILLTOADD = '';
    this.TrnMainObj.BILLTOMOB = '';
    this.TrnMainObj.BILLTOTEL = '';
    //  this.TrnMainObj.ProdList = [];
  }
  a = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen '
  ];
  b = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];

  decimalToWord(frac) {
    if (parseInt(frac[1]) !== 0) {
      return (
        (this.a[Number(frac[1])] ||
          this.b[frac[1][0]] + ' ' + this.a[frac[1][1]]) + 'Paisa Only '
      );
    } else {
      return '';
    }
  }

  digitToWord(num) {
    if (num != null && num !== '' && num !== undefined) {
      let nums = num.toString().split('.');
      let whole = nums[0];
      let w = ('000000000' + whole)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if ((whole = whole.toString()).length > 9) return 'Overflow';
      if (nums.length === 2) {
        let wholeFraction = nums[1];
        let fraction = wholeFraction.substring(0, 2);
        if (
          fraction === '1' ||
          fraction === '2' ||
          fraction === '3' ||
          fraction === '4' ||
          fraction === '5' ||
          fraction === '6' ||
          fraction === '7' ||
          fraction === '8' ||
          fraction === '9'
        ) {
          fraction = fraction + '0';
        } else if (
          fraction === '01' ||
          fraction === '02' ||
          fraction === '03' ||
          fraction === '04' ||
          fraction === '05' ||
          fraction === '06' ||
          fraction === '07' ||
          fraction === '08' ||
          fraction === '09'
        ) {
          fraction = '09';
          fraction = fraction.substring(1, 2);
        }
        let f = ('00' + fraction).substr(-2).match(/^(\d{2})$/);

        if (!w || !f) return;
        let str = '';
        str +=
          parseInt(w[1]) !== 0
            ? (this.a[Number(w[1])] ||
              this.b[w[1][0]] + ' ' + this.a[w[1][1]]) + 'Crore '
            : '';
        str +=
          parseInt(w[2]) !== 0
            ? (this.a[Number(w[2])] ||
              this.b[w[2][0]] + ' ' + this.a[w[2][1]]) + 'Lakh '
            : '';
        str +=
          parseInt(w[3]) !== 0
            ? (this.a[Number(w[3])] ||
              this.b[w[3][0]] + ' ' + this.a[w[3][1]]) + 'Thousand '
            : '';
        str +=
          parseInt(w[4]) !== 0
            ? (this.a[Number(w[4])] ||
              this.b[w[4][0]] + ' ' + this.a[w[4][1]]) + 'Hundred '
            : '';
        str +=
          parseInt(w[5]) !== 0
            ? (this.a[Number(w[5])] ||
              this.b[w[5][0]] + ' ' + this.a[w[5][1]]) +
            'and ' +
            this.decimalToWord(f)
            : '';
        return str;
      } else {
        if (!w) return;
        let str = '';
        str +=
          parseInt(w[1]) !== 0
            ? (this.a[Number(w[1])] ||
              this.b[w[1][0]] + ' ' + this.a[w[1][1]]) + 'Crore '
            : '';
        str +=
          parseInt(w[2]) !== 0
            ? (this.a[Number(w[2])] ||
              this.b[w[2][0]] + ' ' + this.a[w[2][1]]) + 'Lakh '
            : '';
        str +=
          parseInt(w[3]) !== 0
            ? (this.a[Number(w[3])] ||
              this.b[w[3][0]] + ' ' + this.a[w[3][1]]) + 'Thousand '
            : '';
        str +=
          parseInt(w[4]) !== 0
            ? (this.a[Number(w[4])] ||
              this.b[w[4][0]] + ' ' + this.a[w[4][1]]) + 'Hundred '
            : '';
        str +=
          parseInt(w[5]) !== 0
            ? (this.a[Number(w[5])] ||
              this.b[w[5][0]] + ' ' + this.a[w[5][1]]) + 'Only '
            : '';
        return str;
      }
    }
  }

  setDefaultValueInTransaction() {
    if (this.TrnMainObj.MWAREHOUSE == null || this.TrnMainObj.MWAREHOUSE === '') { this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse; }

    this.TrnMainObj.BRANCH = this.userProfile.userBranch;
    this.TrnMainObj.DIVISION = this.userProfile.userDivision;


    this.TrnMainObj.ProdList.forEach(prod => {
      prod.WAREHOUSE = this.TrnMainObj.MWAREHOUSE;
      prod.BRANCH = this.TrnMainObj.BRANCH;
      //   prod.Supplier=this.TrnMainObj.PARAC;
    });
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
      console.log("trnMocde", this.settlementList, this.TrnMainObj.TRNMODE);

      let settlementmode = this.settlementList.filter(
        x => x.DESCRIPTION === this.TrnMainObj.TRNMODE
      )[0];
      if (settlementmode == null) {
        this.alertService.warning('Settlement Mode not found.');
        return false;
      }
      for (let i of this.TrnMainObj.ProdList) {
        if (settlementmode.DECREASE === 0) {
          i.REALQTY_IN = i.Quantity;
          i.ALTQTY_IN = i.Quantity;
          i.RealQty = 0;
          i.AltQty = 0;
        } else if (settlementmode.DECREASE === 1) {
          i.REALQTY_IN = 0;
          i.ALTQTY_IN = 0;
          i.RealQty = i.Quantity;
          i.AltQty = i.Quantity;
        }
      }
      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0)[0];
      if (StockVAlidateItem != null) {

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }
    } else if (
      
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut
    ) {
     this.TrnMainObj.BILLTO = this.TrnMainObj.DIVISION;
    }
    return true;
  }

 checkBatchWisePriceSelectedorNot(){
    var priceSelctionCheckObj = this.TrnMainObj.ProdList.filter(x => x.HasMulitplePrice==1)[0];
    if(priceSelctionCheckObj){
      if(!priceSelctionCheckObj.PriceSelected){
         this.alertService.warning(`Please select the batch wise price for item :${priceSelctionCheckObj.ITEMDESC}`);
         return false;
      }
    } 
    return true;
 }


  checkTransactionValidation() {


    if (
      this.TrnMainObj.Mode === 'VIEW'
       &&
     this.TrnMainObj.VoucherType !== VoucherTypeEnum.TaxInvoiceCancel
    ) {
      alert('Can Not save View Voucher');
      return false;
    }
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue) {
      if (
        this.TrnMainObj.BILLTO == null ||
        this.TrnMainObj.BILLTO === undefined ||
        this.TrnMainObj.BILLTO === ''
      ) {
        alert('Warehouse From field is not detected.');
        return false;
      }
      if (
        this.TrnMainObj.BILLTOADD == null ||
        this.TrnMainObj.BILLTOADD === undefined ||
        this.TrnMainObj.BILLTOADD === ''
      ) {
        alert('Warehouse To field is not detected.');
        return false;
      }
      if (this.TrnMainObj.BILLTOADD === this.TrnMainObj.BILLTO) {
        alert('You cannot transfer to same Warehouse');
        return false;
      }
      let minimumStockValidation = this.TrnMainObj.ProdList.filter(x => x.Quantity > x.MINLEVEL && x.MINLEVEL > 0)[0];
      
      if (minimumStockValidation != null) {
        this.alertService.error("Quantity cannot exceed the minimum stock level");
        return false;
      }
      
    }else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
    ) {
      // if (this.TrnMainObj.PTYPE != 6 && (this.TrnMainObj.TRNAC == null || this.TrnMainObj.TRNAC == "")) {
      //   alert("Please select Transaction account");
      //   return false;
      // }l
      this.checkDeuplicateBarcode();
      if (this.duplicateBarCodeMessage === 'duplicateBarcode') {
        this.alertService.info('duplicate Barcode with item is detected');
        return false;
      }
    
      if (this.schemeApplyClick == false) {
        if (this.schemePresentCheck != false) {
          if (confirm("Do you want to save without applying Scheme?")) {
            return true;
          }
          return false;
        }

      }

    }

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue  || this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
      let checkDateSetingObj = this.TrnMainObj.ProdList.filter(x => x.REQEXPDATE == 1 && (this.nullToZeroConverter(x.MFGDATE) == 0 || this.nullToZeroConverter(x.EXPDATE) == 0))[0];
      let AllProductDetails = [];

      if (checkDateSetingObj != null) {
        this.alertService.info(`Please enter MFD date and EXP date on item:${checkDateSetingObj.ITEMDESC}`);
        return false;
      }

      this.TrnMainObj.ProdList.forEach(x => {
        if (x.PTYPE === 6) {
          AllProductDetails = AllProductDetails.concat(x.ProductDetails);
        }
      })


      let group = AllProductDetails.reduce((r, a) => {
        r[a.IEMI1] = [...r[a.IEMI1] || [], a];
        return r;
      }, {});



      if (AllProductDetails.length >= 1) {
        var seen = {};
        var hasDuplicate = AllProductDetails.some(x => {
          if (seen.hasOwnProperty(x.IEMI1)) {
            this.alertService.info("Duplicate IME1 is detected on Item:" + x.MCODE + "");
            return;
          }
        })
      }

    }

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
      if (this.userSetting.EnableRefno === 1) {
        if (this.TrnMainObj.CHALANNO === '' || this.TrnMainObj.CHALANNO == null) {
          this.alertService.info('Chalan No Could not be Null');
          return false;
        }
      }

    }

    if(this.TrnMainObj.VoucherType=== VoucherTypeEnum.Repack){
      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.ROUTQTY > x.STOCK && x.STOCK > 0)[0];
      if (StockVAlidateItem != null &&
        this.TrnMainObj.ProdList.some(x => x.PTYPE !== 0)) {

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }

    }

    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote && this.fromNotification == false){
      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.Quantity > x.STOCK)[0];
      console.log("@@StockVAlidateItem2",StockVAlidateItem)
      //commented because as per Sirish dai stock validation should not be done in DN
      // if (StockVAlidateItem != null &&
      //   this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {

      //   this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
      //   return false;
      // }
    }

    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut){

      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0)[0];
      if (StockVAlidateItem != null &&
        this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }

    }

    if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived
    ) {

      if (
        this.TrnMainObj.ProdList.some(
          x => new Date(x.MFGDATE) > new Date() && x.MCODE != null &&
            this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        )
      ) {
        this.alertService.warning(
          'Invalid Manufacture Date Item Detected..Please Review the list...'
        );
        return false;
      }



      let invalidQtyObj = this.TrnMainObj.ProdList.filter(x => {
        x.Quantity == 0 && x.FREEQTY == 0
      })[0];
      if (invalidQtyObj != null) {
        this.alertService.info(`Qty Is Not Specified For The Item ${invalidQtyObj.ITEMDESC} (${invalidQtyObj.MENUCODE}) Pls, Check Your Entry.`);
        return false;
      }




      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0)[0];
      if (StockVAlidateItem != null &&
        this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6 && x.PTYPE !== 9)) { //stock not checked for perishable item

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }
    }

   
    if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice && this.activeurlpath!="self-billing" ||this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice) && this.TrnMainObj.AdditionalObj.INVOICETYPE!="REVERSE") {
      let stockExpiry=this.TrnMainObj.ProdList.filter(x => new Date(x.EXPDATE) < new Date(new Date().setDate(new Date().getDate() - 1)) && x.MCODE != null)[0]
      if(stockExpiry!=null)
       {
          this.alertService.warning(
            'Expired Item Detected!  Product:'+stockExpiry.ITEMDESC +"\n" +'Exp date:'+stockExpiry.EXPDATE 
          );
          return;
        }
        // if(this.TrnMainObj.ProdList.filter(x => x.BATCHID==null||x.BATCHID==""||x.BATCHID==undefined)[0])
        // {
        // this.alertService.warning(
        //   "Batch Id cannot be null" 
        // );
        // return;
        // }
      }
    return true;
  }





  checkDeuplicateBarcode() {
    console.log('batlist', this.TrnMainObj.ProdList);
    let valuesArray = this.TrnMainObj.ProdList.map(x => { return x.BATCH; });
    let duplicate = valuesArray.some((item, x) => {
      return valuesArray.indexOf(item) !== x;
    });
    console.log('duplicatevalue', duplicate);
  }



  initialFormLoad(voucherType) {

    this.TrnMainObj = <TrnMain>{};
    this.masterService.productDetailsListHold = [];
    this.showPerformaApproveReject = false;
    this.TrnMainObj.AdditionalObj = <TrnMain_AdditionalInfo>{};
    this.TrnMainObj.TransporterEway = <Transporter_Eway>{};
    this.TrnMainObj.IMPORTDETAILS = <IMPORT_DETAILS>{};

    // this.TrnMainObj.TrntranList = [];
    this.TrnMainObj.guid = uuid();
    this.TrnMainObj.Mode = 'NEW';
    this.TrnMainObj.DIVISION = this.userProfile.userDivision;
    if(this.arouter.snapshot.url[0]?.path === 'self-billing'){
      this.TrnMainObj.MWAREHOUSE = '';
      }
    else{
      this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
    }
    
    this.TrnMainObj.PhiscalID = this.masterService.PhiscalObj.PhiscalID;
    console.log('invchtyp',voucherType,VoucherTypeEnum.StockIssueReturn);
    switch (voucherType) {
      case VoucherTypeEnum.PurchaseOrder:
        this.TrnMainObj.VoucherPrefix = 'PO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PurchaseOrder;
        if(this.urlpath == "purchase-import-order"){
          this.TrnMainObj.CNDN_MODE = 10;
          this.pageHeading = "Import Purchase Order";
          this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE_ORDER";
        }else{
          this.TrnMainObj.CNDN_MODE = 0;
          this.pageHeading = 'Purchase Order';
        }
        break;
      case VoucherTypeEnum.Purchase:
        this.TrnMainObj.VoucherPrefix = 'PI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
        if (this.urlpath == "purchase-import") {
          this.TrnMainObj.CNDN_MODE = 10;
          this.pageHeading = "Import Purchase";
          this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE";
        } else {
          this.TrnMainObj.CNDN_MODE = 0;
          this.pageHeading = 'Purchase Invoice';
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.AdditionalObj.tag = "LOCAL_PURCHASE";
        }


        break;
      

      // case VoucherTypeEnum.DebitNote:
      //   this.TrnMainObj.VoucherPrefix = "DN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
      //   this.pageHeading = "Debit Note";
      //   break;
      // case VoucherTypeEnum.DebitNote:
      //   this.TrnMainObj.VoucherPrefix = "DN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
      //   this.pageHeading = "Debit Note";
      //   break;
   

      case VoucherTypeEnum.SalesOrder:
        this.TrnMainObj.VoucherPrefix = 'SO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.SalesOrder;
        this.voucherNoHeader = 'SO No';
        this.pageHeading = 'Sales Order';
        break;
      case VoucherTypeEnum.Sales:
        this.TrnMainObj.VoucherPrefix = 'SI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Sales;
        this.pageHeading = 'Sales Invoice';
        break;
      case VoucherTypeEnum.TaxInvoice:
        this.TrnMainObj.VoucherPrefix = 'TI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoice;
        this.TrnMainObj.TRNMODE = this.userSetting.GblEnableMixedTenderMode == 1 ? 'cash': 'credit';
        console.log("activeurl", this.arouter.snapshot.url[0].path);
        if (this.arouter.snapshot.url[0].path === 'self-billing') {
          this.pageHeading = 'Self Billing';
          this.TrnMainObj.TRNMODE = 'credit';
        } else {
          this.pageHeading = 'Tax Invoice'
        }
        if (this.userProfile.CompanyInfo.ORG_TYPE === 'retailer' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'ak' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'ck' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'pms') {
          this.masterService.getCurrentDate().subscribe(
            date => {
              this.TrnMainObj.DeliveryDate = date.Date.substring(0, 10);
            },
            error => {
              this.masterService.resolveError(
                error,
                'voucher-date - getCurrentDate'
              );
            }
          );
        }
        break;
      // case VoucherTypeEnum.CreditNote:
      //   this.TrnMainObj.VoucherPrefix = "CN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.CreditNote;
      //   this.pageHeading = "Credit Note";
      //   break;
      case VoucherTypeEnum.StockIssue:
        this.TrnMainObj.VoucherPrefix = 'IS';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssue;
        if (this.arouter.snapshot.url[0].path === 'stock-issue-approval') {
          this.pageHeading = 'Stock Issue Approval';
        }else{
          this.pageHeading = 'Stock Issue';
          this.TrnMainObj.VoucherPrefix = 'SS';
        }
      this.TrnMainObj.BILLTO = this.userProfile.userWarehouse;
        break;
        case VoucherTypeEnum.Stockadjustment:
          this.TrnMainObj.VoucherPrefix = 'SA';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Stockadjustment;
            this.pageHeading = 'Stock Adjustment';
          break;
          case VoucherTypeEnum.BatchAdjustment:
            this.TrnMainObj.VoucherPrefix = 'BA';
            this.TrnMainObj.VoucherType = VoucherTypeEnum.BatchAdjustment;
              this.pageHeading = 'Batch Adjustment';
            break;
      case VoucherTypeEnum.StockSettlement:
        this.TrnMainObj.VoucherPrefix = 'DM';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.StockSettlement;
        this.pageHeading = 'Stock Settlement';
        break;
      case VoucherTypeEnum.OpeningStockBalance:
        this.TrnMainObj.VoucherPrefix = 'OP';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.OpeningStockBalance;
        this.pageHeading = 'Opening Stock Entry';
        break;
      case VoucherTypeEnum.BranchTransferIn:
        this.TrnMainObj.VoucherPrefix = 'TR';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.BranchTransferIn;
        this.pageHeading = 'Branch Transfer In';
        this.TrnMainObj.VoucherAbbName = 'TR';
        break;
        case VoucherTypeEnum.Repack:
          this.TrnMainObj.VoucherPrefix = 'RP';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Repack;
          this.pageHeading = 'Repack';
          this.TrnMainObj.VoucherAbbName = 'RP';
          break;  
      case VoucherTypeEnum.BranchTransferOut:
        this.TrnMainObj.VoucherPrefix = 'TO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.BranchTransferOut;
        this.TrnMainObj.VoucherAbbName = 'TO';
        if (this.arouter.snapshot.url[0].path === 'transfer-out-approval') {
          this.pageHeading = 'Branch Transfer Out Approval'
        }else{
          this.pageHeading = 'Branch Transfer Out';
        }
        break;
      case VoucherTypeEnum.RequisitionEntry:
          this.TrnMainObj.VoucherPrefix = 'RQ';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.RequisitionEntry;
          this.TrnMainObj.VoucherAbbName = 'RQ';
          this.pageHeading = 'Requisition Entry';
          console.log("reqentry");
          break;  
      case VoucherTypeEnum.PerformaSalesInvoice:
        this.TrnMainObj.VoucherPrefix = 'PP';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PerformaSalesInvoice;
        this.pageHeading = 'Proforma Invoice';
        break;
      case VoucherTypeEnum.PurchaseOrderCancel:
        this.TrnMainObj.VoucherPrefix = 'PC';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PurchaseOrderCancel;
        this.pageHeading = 'PO Cancel';
        break;
      // for account vouchers
      case VoucherTypeEnum.Journal:
        this.TrnMainObj.VoucherPrefix = 'JV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Journal;
        this.TrnMainObj.TrntranList = [];
        this.pageHeading = 'Journal Voucher';
        this.TableAcHeader = 'Acount (A/C)';
        this.addRowForTransaction(-1);
        break;
      case VoucherTypeEnum.PaymentVoucher:
        this.TrnMainObj.VoucherPrefix = 'PV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PaymentVoucher;
        this.TrnMainObj.TrntranList = [];
        this.pageHeading = 'Expenses Voucher ';
        this.TableAcHeader = 'Party/Expenses A/C';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.ReceiveVoucher:
        this.TrnMainObj.VoucherPrefix = 'RV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ReceiveVoucher;
        this.pageHeading = 'Income Voucher';
        this.TrnMainObj.TrntranList = [];
        this.TableAcHeader = 'Party/Income A/C';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.DebitNote:
        this.TrnMainObj.VoucherPrefix = 'DN';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
        this.TrnMainObj.VoucherAbbName = 'DN';
        this.pageHeading = 'Debit Note';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.CreditNote:
        this.TrnMainObj.VoucherPrefix = 'CN';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.CreditNote;
        this.pageHeading = 'Credit Note';
        this.voucherNoHeader = 'Return No';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        this.TrnMainObj.DOFULLRETURN = this.DOFULLRETURN = this.userSetting.REFBILLCOMPULSORINCNOTE;
        this.TrnMainObj.TRNMODE = 'credit';
        this.TrnMainObj.DOMANUALSALESRETURN = 0;

        break;

      case VoucherTypeEnum.ContraVoucher:
        this.TrnMainObj.VoucherPrefix = 'CE';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ContraVoucher;
        this.pageHeading = 'Contra Voucher';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        break;

      // configuration
      case VoucherTypeEnum.AccountOpeningBalance:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.AccountOpeningBalance;
        this.pageHeading = 'Account Opening Balance';
        this.TrnMainObj.TrntranList = [];
        this.TrnMainObj.VoucherPrefix = 'OB';
        this.TrnMainObj.VoucherAbbName = 'OB';
        this.TrnMainObj.VoucherName = 'OBBILL';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.PartyOpeningBalance:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PartyOpeningBalance;
        this.pageHeading = 'Party Opening Balance';
        this.TrnMainObj.TrntranList = [];
        this.TrnMainObj.VoucherPrefix = 'AO';
        this.TrnMainObj.VoucherAbbName = 'AO';
        this.TrnMainObj.VoucherName = 'OPPARTYBILL';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.TaxInvoiceCancel:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoiceCancel;
        this.pageHeading = 'Bill Cancel';
        this.TrnMainObj.VoucherPrefix = 'SC';
        this.TrnMainObj.VoucherAbbName = 'SC';
        break;
      case VoucherTypeEnum.Quotation:
        this.TrnMainObj.VoucherPrefix = 'QT';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Quotation;
        this.pageHeading = 'Quotation';
        break;
      case VoucherTypeEnum.GoodsReceived:
        this.TrnMainObj.VoucherPrefix = 'GR';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.GoodsReceived;
        this.pageHeading = 'Goods Received Note';
        this.TrnMainObj.TRNMODE = 'credit';
        // this.TrnMainObj.AdditionalObj.tag = "LOCAL_PURCHASE";
        break;
      case VoucherTypeEnum.StockIssueReturn:
          this.TrnMainObj.VoucherPrefix = 'IR';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssueReturn;
          this.TrnMainObj.VoucherAbbName = 'IR';   
          this.pageHeading = 'Stock Issue Return';
          break; 
          case VoucherTypeEnum.PreDebitNote:
            this.TrnMainObj.VoucherPrefix = 'DD';
            this.TrnMainObj.VoucherType = VoucherTypeEnum.PreDebitNote;
            this.TrnMainObj.VoucherAbbName = 'DD';
            this.pageHeading = 'Pre Debit Note';
            this.TrnMainObj.TrntranList = [];
            this.addRowForTransaction(-1);
            break; 
      case VoucherTypeEnum.ProductionEntry:
        this.TrnMainObj.VoucherPrefix = this.productionEntryPrefix;
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ProductionEntry;
        this.pageHeading = 'Production Entry';
        this.TrnMainObj.VoucherAbbName =this.productionEntryPrefix;
        this.TrnMainObj.AdditionalObj.RETURNTYPE = "production";
        if(this.activeurlpath && this.activeurlpath.toLowerCase() == 'consumption-entry'){
          this.pageHeading = 'Consumption Entry';
          this.TrnMainObj.AdditionalObj.RETURNTYPE = "consumption";
        }
        this.addConsumptionRow();
        break;
        case VoucherTypeEnum.BatchIDTransfer:
          this.TrnMainObj.VoucherType = VoucherTypeEnum.BatchIDTransfer;
          this.pageHeading = "BatchID Transfer";
          this.TrnMainObj.VoucherPrefix = "BT";
          this.TrnMainObj.VoucherAbbName = "BT";
          this.TrnMainObj.VoucherName = "BatchIDTransfer";
          break;

        
    }

    console.log('ll',this.TrnMainObj.VoucherType);

    this.getVoucherNumber();

    this.getCurrentDate();

    if (this.TrnMainObj.VoucherType === 58)
      return;

    this.addRow();
    this.masterService.isAddFormShown_1 = false;
  }


  getVoucherNumber() {
    if (this.arouter.snapshot.url[0]?.path === 'transfer-out-approval') {
      return;
    }
    if(this.arouter.snapshot.url && this.arouter.snapshot.url[0] && this.arouter.snapshot.url[0].path == 'add-stock-issue'){          
      this.TrnMainObj.VoucherPrefix = "SS";
    }
    if(this.arouter.snapshot.url && this.arouter.snapshot.url[0] && this.arouter.snapshot.url[0].path == 'stock-issue-approval'){          
      this.TrnMainObj.VoucherPrefix = "IS";
    }

    this.masterService.getVoucherNo(this.TrnMainObj).subscribe(res => {
      if (res.status === 'ok') {
        console.log("get VCHR NO",  res.result );
        let TMain = <TrnMain>res.result;
        this.TrnMainObj.VCHRNO = TMain.VCHRNO;
        console.log("@@TMain.VCHRNO",TMain.VCHRNO)
        console.log("@@this.TrnMainObj.VCHRNO",this.TrnMainObj.VCHRNO)
        if(TMain.VoucherPrefix != 'QT'){
        // this.TrnMainObj.CHALANNO = TMain.CHALANNO;
        }
        this.TrnMainObj.PhiscalID = TMain.PhiscalID;
        this.TrnMainObj.PARAC = TMain.PARAC;
        // this.disableOnGRNload =false;
      } else {
        alert('Failed to retrieve VoucherNo');
      }
    });
  }

  getVoucherNumberForTaxCancel() {
    this.masterService.getVoucherNo(this.TrnMainObj).subscribe(res => {
      if (res.status === 'ok') {
        let TMain = <TrnMain>res.result;
        this.TrnMainObj.CHALANNO = TMain.CHALANNO;
        this.TrnMainObj.PhiscalID = TMain.PhiscalID;
        this.TrnMainObj.PARAC = TMain.PARAC;
      } else {
        alert('Failed to retrieve VoucherNo');
      }
    });
  }

  getCurrentDate() {
    this.masterService.getCurrentDate().subscribe(
      date => {
        this.TrnMainObj.TRNDATE = date.Date.substring(0, 10);
        this.TrnMainObj.TRN_DATE = date.Date.substring(0, 10);
        this.TrnMainObj.BSDATE = this.toBSDate(this.TrnMainObj.TRNDATE.toString());
        this.TrnMainObj.BS_DATE = this.toBSDate(this.TrnMainObj.TRN_DATE.toString());
        console.log("bd date", this.TrnMainObj.BSDATE, this.TrnMainObj.BS_DATE);
        
        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance){ 
          this.TrnMainObj.TRNDATE = this.userProfile.CompanyInfo.FBDATE.split('T')[0];
          this.TrnMainObj.BSDATE = this.userProfile.CompanyInfo.FBDATE_BS;
        }
      },
      error => {
        this.masterService.resolveError(error, 'voucher-date - getCurrentDate');
      }
    );
  }

   getBsDate(date) {
    let adbs = require('ad-bs-converter');
    console.log("CHANGE TO BS YOYOYO", date);
    let adDate = (date.replace('-', '/')).replace('-', '/');
    let bsDate = adbs.ad2bs(adDate);
    return ( (bsDate.en.day == '1' || bsDate.en.day == '2' || bsDate.en.day == '3' || bsDate.en.day == '4' || bsDate.en.day == '5' || bsDate.en.day == '6' || bsDate.en.day == '7' || bsDate.en.day == '8' || bsDate.en.day == '9' ? '0' + bsDate.en.day : bsDate.en.day) + '/'+ (bsDate.en.month == '1' || bsDate.en.month == '2' || bsDate.en.month == '3' || bsDate.en.month == '4' || bsDate.en.month == '5' || bsDate.en.month == '6' || bsDate.en.month == '7' || bsDate.en.month == '8' || bsDate.en.month == '9' ? '0' + bsDate.en.month : bsDate.en.month) + '/' + bsDate.en.year);
  }

  addRow() {
    console.log("ADDROW");
    try {
      if (this.TrnMainObj.ProdList == null) {
        this.TrnMainObj.ProdList = [];
      }
      // Duplicate Item with same batch check
      // let duplicatecheck = 0;
      // if (this.TrnMainObj.ProdList == null) {
      //   this.TrnMainObj.ProdList = [];
      // }
      // this.TrnMainObj.ProdList.reduce((aac, obj) => {
      //   var existitem = aac.find(
      //     item => item.MCODE === obj.MCODE && item.BATCH === obj.BATCH
      //   );
      //   if (existitem && this.TrnMainObj.VoucherType != VoucherTypeEnum.SalesOrder) {
      //     alert("Duplicate Item with same batch detected...");
      //     duplicatecheck = 1;
      //     return;
      //   }
      //   aac.push(obj);
      //   return aac;
      // }, []);
      // if (duplicatecheck == 1) {
      //   return false;
      // }

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
        (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice && this.activeurlpath !="self-billing")||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice
      ) {
        // if (
        //   this.TrnMainObj.ProdList.some(x => x.EXPDATE == null && x.MCODE != null) &&
        //   this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        // ) {
        //   this.alertService.warning(
        //     "Expiry Date of Item Not Detected..Please Review the list..."
        //   );
        //   return false;
        // }

        let expitem = this.TrnMainObj.ProdList.filter(x => new Date(x.EXPDATE) < new Date(new Date().setDate(new Date().getDate() - 1)) && x.MCODE != null)[0];
        if (expitem != null &&
          this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {
          this.alertService.warning(
            'Expired Item Detected with code : ' + expitem.MCODE + '..Please Review the list...'
          );
          return false;
        }
        if (
          this.TrnMainObj.ProdList.some(
            x => new Date(x.MFGDATE) > new Date() && x.MCODE != null &&
              this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
          )
        ) {
          console.log("reachP", this.TrnMainObj.ProdList);
          this.alertService.warning(
            'Invalid Manufacture Date Item Detected..Please Review the list...'
          );
          return false;
        }
        // if (
        //   this.TrnMainObj.ProdList.some(
        //     x => x.MCODE != null && this.nullToZeroConverter(x.Quantity) < 1
        //   ) &&
        //   this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        // ) {
        //   this.alertService.warning(
        //     `Qty Is Not Specified For The Item ${this.TrnMainObj.ProdList[this.]}. Pls, Check Your Entry.`
        //   );
        //   return false;
        // }

        // let invalidQtyValidate = this.TrnMainObj.ProdList.filter( x => x.Quantity <=0)[0]
        // if(invalidQtyValidate !=null){
        //   this.alertService.warning(
        //     `Qty Is Not Specified For The Item ${invalidQtyValidate.ITEMDESC}(${invalidQtyValidate.MENUCODE}). Pls, Check Your Entry.`
        //   );
        //   return false;
        // }

      }
      // this.TrnMainObj.ProdList.forEach(x => x.inputMode = false);
      this.newRowAdd()
      // let newRow = <TrnProd>{};
      // newRow.inputMode = true;
      // newRow.MENUCODE = null;
      // newRow.ITEMDESC = null;
      // newRow.RATE = null;
      // newRow.NCRATE = null;
      // newRow.AMOUNT = null;
      // newRow.INDDISCOUNTRATE = null;
      // newRow.INDDISCOUNT = null;
      // newRow.DISCOUNT = null;
      // newRow.VAT = null;
      // newRow.NETAMOUNT = null;
      // newRow.ITEMTYPE = null;
      // newRow.RECEIVEDTYPE = null;
      // newRow.WAREHOUSE = null;
      // newRow.BC = null;
      // newRow.ALTERNATEQUANTIY = null;
      // newRow.PRATE =0;

      // this.TrnMainObj.ProdList.push(newRow);
console.log("prodList",this.TrnMainObj.ProdList)
      this.checkBatchWiseApiResponses = false;
      return true;
    } catch (ex) {
      // this.alertService.error(ex);
      return false;
    }

    
  }

  loadSODataToSales(VCHR) {
    this.masterService
      .masterPostmethod('/getSalesBillFromSO', { REFBILL: VCHR })
      .subscribe(
        data => {
          if (data.status === 'ok') {
            this.TrnMainObj = data.result;
            // this.TrnMainObj.Mode = "VIEW";
            // this.ReCalculateBill();

            this.trnmainBehavior.next(this.TrnMainObj);
          }
        },
        error => {
          this.trnmainBehavior.complete();
        },
        () => this.trnmainBehavior.complete()
      );
    // });
  }

  RealQuantitySet(i, CONFACTOR: number = 1) {
    if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DeliveryReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry
    ) {
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) * CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].RealQty = 0;
      this.TrnMainObj.ProdList[i].AltQty = 0;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PreDebitNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Delivery ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation
    ) {
      // alert("DispatchOut")
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
    ) {
      if (this.TrnMainObj.ProdList[i].Quantity > 0) {
        // .CONFACTOR *
        this.TrnMainObj.ProdList[i].REALQTY_IN =
          this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
          CONFACTOR;
        this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
          i
        ].Quantity;
        this.TrnMainObj.ProdList[i].RealQty = 0;
        this.TrnMainObj.ProdList[i].AltQty = 0;
        this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      } else if (this.TrnMainObj.ProdList[i].Quantity < 0) {
        // .CONFACTOR *
        this.TrnMainObj.ProdList[i].RealQty =
          -1 * this.TrnMainObj.ProdList[i].Quantity * CONFACTOR;
        this.TrnMainObj.ProdList[i].AltQty =
          -1 * this.TrnMainObj.ProdList[i].Quantity;
        this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
        this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
        this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      }
    } else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
      /*
           none of the value are 0 here
           later while save the value it will checks StockSettlement and then return 0 there.
           */
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].Quantity;
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrderCancel
    ) {
      /*
           none of the value are 0 here
           later while save the value it will checks StockSettlement and then return 0 there.
           */
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].Quantity;
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoiceCancel
    ) {
      this.TrnMainObj.ProdList[i].REALQTY_IN = this.nullToZeroConverter(
        this.TrnMainObj.ProdList[i].RealQty
      );
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].AltQty;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      this.TrnMainObj.ProdList[i].RealQty = 0;
      this.TrnMainObj.ProdList[i].AltQty = 0;
    } else {
      alert('Vouchertype not found please contact admin.');
    }
  }

  // setunit(baseRate: number, baseRate2: number, activerowIndex, altunitObj) {
  //   if (altunitObj == null) {
  //     altunitObj = <any>{};
  //   }
  //   this.TrnMainObj.ProdList[activerowIndex].CONFACTOR = altunitObj.CONFACTOR;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = altunitObj.ALTUNIT;
  //   this.TrnMainObj.ProdList[activerowIndex].UNIT = altunitObj.BASEUOM;
  //   this.TrnMainObj.ProdList[activerowIndex].RATE = baseRate;
  //   this.TrnMainObj.ProdList[activerowIndex].REALRATE = baseRate;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
  //     baseRate * altunitObj.CONFACTOR;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTRATE2 =
  //     baseRate2 * altunitObj.CONFACTOR;
  //     this.TrnMainObj.ProdList[activerowIndex].ALTMRP =  this.TrnMainObj.ProdList[activerowIndex].MRP* altunitObj.CONFACTOR;
  //   this.RealQuantitySet(activerowIndex, altunitObj.CONFACTOR);
  // }
  setAltunitDropDownForView(activerowIndex) {
 
    if (this.TrnMainObj.ProdList[activerowIndex].Ptype == 10) {
      //service item like delivery charges
    }
    else {
      if (this.TrnMainObj.ProdList[activerowIndex].ALTUNIT == null) { console.log("null", this.TrnMainObj.ProdList[activerowIndex].MCODE); }
      this.TrnMainObj.ProdList[
        activerowIndex
      ].ALTUNITObj = this.TrnMainObj.ProdList[
        activerowIndex
      ].Product.AlternateUnits.filter(
        x => (x.ALTUNIT == null ? '' : x.ALTUNIT).toLowerCase() == (this.TrnMainObj.ProdList[activerowIndex].ALTUNIT == null ? '' : this.TrnMainObj.ProdList[activerowIndex].ALTUNIT).toLowerCase()
      )[0];
    }
  }

  setAltunitDropDownForViewStock(activerowIndex) {
    this.masterService.masterGetmethod(
      '/getAltUnitsOfItem/' + this.TrnMainObj.ProdList[activerowIndex].MCODE
    )
      .subscribe(res => {
        if (res.status = 'ok') {
          this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = JSON.parse(res.result);
        }
      });

    this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj =
      this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ALTUNIT === this.TrnMainObj.ProdList[activerowIndex].ALTUNIT);
  }

  getItemGroupWiseRate(activerowIndex) {
    
    let groupWiseRate=[];
    this.masterService.masterGetmethod(
      `/getRateGroupWiseRATE?mcode=${this.TrnMainObj.ProdList[activerowIndex].MCODE}&division=${this.userProfile.userDivision}`
    )
      .subscribe(res => {
        if (res.status = 'ok') {
          groupWiseRate = JSON.parse(res.result);
          if(groupWiseRate.length){
            
            this.TrnMainObj.ProdList[activerowIndex].RATE =  (this.userSetting.ENABLE_WSRATE_INSORDER==1)? groupWiseRate[0].RATE_B: groupWiseRate[0].RATE;            
            if(this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER ==0 && this.TrnMainObj.ProdList[activerowIndex].ISVAT ==1){
              this.TrnMainObj.ProdList[activerowIndex].RATE =  (this.TrnMainObj.ProdList[activerowIndex].RATE * this.userSetting.VatConRate);
            }                        
            if(this.TrnMainObj.ProdList[activerowIndex].ALTUNIT != this.TrnMainObj.ProdList[activerowIndex].BASEUNIT){
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE * this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR;
            }else{
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE;
            }
          }
        }
      });
  }

  getItemRelatedDiscount(activerowIndex:number,discountParam:any):void {
    let itemWiseDiscount=[];
    let itemObj= {mode:null, data: discountParam};
    this.masterService.masterPostmethod(
      `/getItemRealtedDiscount`,itemObj
    )
      .subscribe(res => {
        if (res.status == 'ok') {
          itemWiseDiscount = res.result;
          
          if(itemWiseDiscount.length){
             
            if(this.userSetting.EnableContractPrice ==1){
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = itemWiseDiscount[0].RATEORDIS;  //Direct change on rate field if contract price is assign for party
              this.TrnMainObj.ProdList[activerowIndex].RATE = itemWiseDiscount[0].RATEORDIS;  
            }else{
              this.TrnMainObj.ProdList[activerowIndex].INDDISCOUNT = itemWiseDiscount[0].RATEORDIS; //Treat contract price as discount
            }
          
            this.ReCalculateBillWithNormal();
          }
        }
      });
  }



  checkProductMulitplePriceBatchWise(activerowIndex) {
    this.masterService.masterGetmethod(
      '/getProuductMultiplePriceBatchWise/' + this.TrnMainObj.ProdList[activerowIndex].MCODE
    )
      .subscribe(res => {

        if (res.status == 'ok') {
          if (res.message == 'SHOWPRICEPOPUP') {
            this.showBatchWiseQtyEntryPopup = 'SHOWPRICEPOPUP';
          } else {
            this.showBatchWiseQtyEntryPopup = '';
          }
        }
      });
  }


  getAllUnitsOfItem(activerowIndex) {
    let alternateUnitList = [];
    let MappedalternateUnitList = [];
    this.masterService
      .masterGetmethod(
        '/getAllUnitsOfItem/' +
        this.TrnMainObj.ProdList[activerowIndex].MCODE
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            console.log("Test",JSON.parse(res.result))
            if (
              this.TrnMainObj.ProdList[activerowIndex].Product == null
            ) {
              this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
            }
            this.TrnMainObj.ProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ProdList[activerowIndex].MCODE;
            alternateUnitList = JSON.parse(res.result);
            for (let it of alternateUnitList) {
              let alternateUnitObj = <AlternateUnit>{};
              alternateUnitObj.ALTUNIT = it.UNIT;
              alternateUnitObj.PRATE_A = it.PRATE_A;
              alternateUnitObj.MCODE = it.MCODE;
              alternateUnitObj.ISDEFAULTPUNIT = it.ISDEFAULTPUNIT;
              alternateUnitObj.ISDEFAULT = it.ISDEFAULT;
              alternateUnitObj.CONFACTOR = this.nullToZeroConverter(it.CONFACTOR) == 0 ? 1 : it.CONFACTOR;
              alternateUnitObj.RATE_A = it.RATE_A;
              alternateUnitObj.SRATE_DISCOUNT = it.SRATE_DISCOUNT;
              alternateUnitObj.WSRATE_DISCOUNT = it.WSRATE_DISCOUNT;
              MappedalternateUnitList.push(alternateUnitObj);
            }

            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
              let baseUnitList = MappedalternateUnitList.filter(x => x.ALTUNIT == this.TrnMainObj.ProdList[activerowIndex].BASEUNIT);
              if (baseUnitList != null) {
                this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = baseUnitList;
              } else {
                this.alertService.info("Product UNIT is not available");
              }
            } else {
              this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = MappedalternateUnitList;
            }

            this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits[0];
            this.TrnMainObj.ProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
            this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.CONFACTOR;

            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
              this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].BASEUNIT;
            } else {
              let isdefaultPIUNIT = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ISDEFAULTPUNIT == 1)[0];
              if (isdefaultPIUNIT != null) {
                this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = isdefaultPIUNIT.ALTUNIT;
                this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = isdefaultPIUNIT.CONFACTOR;
                console.log("prodList",this.TrnMainObj.ProdList);
                if (this.activeurlpath != 'add-sales-invoice-wholesale') {
                
                  this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE * this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR;
        
                }
              } else {
                this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
              }
            }
          } else {
          }
        },
        error => {
        }
      );

  }


  getAllSelingPriceOfItem(activerowIndex) {
    let altSellingPriceList = [];
    let mappedAltSellingPriceList = [];
    let mcode;
    if (this.masterService.is_PurchaseOrder == true) {
      mcode = this.TrnMainObj.PODetailList[activerowIndex].MCODE;
    } else {
      mcode = this.TrnMainObj.ProdList[activerowIndex].MCODE;
    }
    this.masterService
      .masterGetmethod(
        '/getAllSellingPirceOfItem/' +
        mcode
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (this.masterService.is_PurchaseOrder != true) {
              if (
                this.TrnMainObj.ProdList[activerowIndex].Product == null
              ) {
                this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
              }
            }

            //this.priceChangeList = this.TrnMainObj.ProdList[activerowIndex].Product.AltSellingPrices;
            altSellingPriceList = JSON.parse(res.result);
            console.log(res.result);
            console.log("@get all selling price of item", altSellingPriceList);
            let i = 1;
            for (let item of altSellingPriceList) {
              let altSellingPriceObj = <AltSellingPrice>{}
              altSellingPriceObj.SNO = i
              altSellingPriceObj.UNIT = item.UNIT;
              altSellingPriceObj.MCODE = item.MCODE;
              altSellingPriceObj.CRATE_A = item.RATE;
              altSellingPriceObj.WSRATE_A=item.W_MRP;
              altSellingPriceObj.NRATE_A = null;
              altSellingPriceObj.CONFACTOR = item.CONFACTOR;
              mappedAltSellingPriceList.push(altSellingPriceObj);
              i++;
            }
            this.priceChangeList = mappedAltSellingPriceList;
            if (this.masterService.is_PurchaseOrder != true) {
              this.TrnMainObj.ProdList[activerowIndex].Product.AltSellingPrices = mappedAltSellingPriceList;
              // this.showPriceChangeDetail = true; 
              this.selectedProdcutRecMargin = this.TrnMainObj.ProdList[activerowIndex].RECRATE;
            }
          } else {
          }
        },
        error => {
        }
      );

  }




  getPricingOfItem(activerowIndex, batchcode = '', getAltunitListFromApi = true) {

    let getpricingObj = {
      mcode: this.TrnMainObj.ProdList[activerowIndex].MCODE,
      batchcode: batchcode,
    };



    this.masterService
      .masterPostmethod('/getBatchwiseItemPriceanddiscounts', getpricingObj)
      .subscribe(res => {
        if (res.status === 'ok') {
          this.AssignSellingPriceAndDiscounts(JSON.parse(res.result), activerowIndex);
          if (getAltunitListFromApi) {
            this.masterService
              .masterGetmethod(
                '/getAltUnitsOfItem/' +
                this.TrnMainObj.ProdList[activerowIndex].MCODE
              )
              .subscribe(
                res => {
                  if (res.status === 'ok') {
                    if (
                      this.TrnMainObj.ProdList[activerowIndex].Product == null
                    ) {
                      this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
                    }
                    this.TrnMainObj.ProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ProdList[activerowIndex].MCODE;
                    this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = JSON.parse(res.result);
                    this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits[0];
                    this.TrnMainObj.ProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
                    this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.CONFACTOR;


                    // let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
                    // let rate2 = 0;
                    // if (this.TrnMainObj.VoucherType == VoucherTypeEnum.PurchaseOrder || this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase) {
                    //   rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
                    // } else {
                    //   rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
                    // }
                    // this.setunit(
                    //   rate1,
                    //   rate2,
                    //   activerowIndex,
                    //   this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj
                    // );
                  } else {
                  }
                },
                error => {
                }
              );
          } else {
            let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
            let rate2 = 0;
            if (
              this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
              this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase 
            ) {
              rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
            } else {
              rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
            }
            // this.setunit(
            //   rate1,
            //   rate2,
            //   activerowIndex,
            //   this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj
            // );
            this.ReCalculateBillWithNormal();
          }
        } else {
          alert(res.result);
        }
      });
  }

  AssignSellingPriceAndDiscounts(PriceRate: PriceRate[], activerowIndex) {
    // Unused for AtoZ commented by Bzu
    console.log('Check PriceRate', PriceRate);
    let priceidO = PriceRate.filter(x => x.ColType === 'priceid')[0];
    debugger;
    if (priceidO != null) {
      this.TrnMainObj.ProdList[activerowIndex].PRICEID = priceidO.ColName;
    }
    let orgType = this.userProfile.CompanyInfo.ORG_TYPE;
    if (orgType.toLowerCase() === 'superdistributor') {
      let spp = PriceRate.filter(x => x.ColName === 'superdistributorlandingprice')[0];
      let ssp = <PriceRate>{};

      ssp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'superdistributor')[0];

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlementEntryApproval ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Stockadjustment
      ) {
        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          spp === undefined || spp == null ? 0 : this.nullToZeroConverter(spp.Value);
        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          ssp === undefined || ssp == null ? 0 : this.nullToZeroConverter(ssp.Value);
      }
      else {
        //  debugger;
        this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          ssp === undefined || ssp == null ? 0 : this.nullToZeroConverter(ssp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          spp === undefined || spp == null ? 0 : this.nullToZeroConverter(spp.Value);
      }
    } else if (orgType.toLowerCase() === 'distributor') {
      let dpp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'superdistributor')[0];
      let dsp = PriceRate.filter(x => x.ColName === 'distributor' && x.ColType === 'sellingprice')[0];

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
      ) {
        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          dpp === undefined || dpp == null ? 0 : this.nullToZeroConverter(dpp.Value);

        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          dsp === undefined || dsp == null ? 0 : this.nullToZeroConverter(dsp.Value);
      } else {
        this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          dsp === undefined || dsp == null ? 0 : this.nullToZeroConverter(dsp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          dpp === undefined || dpp == null ? 0 : this.nullToZeroConverter(dpp.Value);
      }
    } else {
      let rsp = <any>{};
      let rpp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'distributor')[0];

      rsp = PriceRate.filter(
        x => x.ColName === 'retailer' && x.ColType === 'sellingprice'
      )[0];
      // }

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
      ) {
        this.TrnMainObj.ProdList[
          activerowIndex
        ].PRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].REALRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE =
          rpp === undefined || rpp == null
            ? 0
            : this.nullToZeroConverter(rpp.Value);

        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          rsp === undefined || rsp == null
            ? 0
            : this.nullToZeroConverter(rsp.Value);
      } else {
        this.TrnMainObj.ProdList[
          activerowIndex
        ].REALRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE =
          rsp === undefined || rsp.Value == null
            ? 0
            : this.nullToZeroConverter(rsp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          rpp === undefined || rpp == null
            ? 0
            : this.nullToZeroConverter(rpp.Value);
      }
    }
  }
  MergeSameItemWithSameBatchOfProd() {
    // let MergedProd=[];
    // Rx.Observable.from(this.TrnMainObj.ProdList)
    // .groupBy(x =>{ x.MCODE,x.ITEMDESC,x.MENUCODE}) // using groupBy from Rxjs
    // .flatMap(group => group.toArray())// GroupBy dont create a array object so you have to flat it
    // .map(g => {// mapping
    //   return {
    //     MCODE: g[0].MCODE,//take the first name because we grouped them by name
    //     Quantity: _.sumBy(g, 'Quantity'),
    //     REALQTY_IN: _.sumBy(g, 'REALQTY_IN'),
    //   }
    // })
    // .toArray() //.toArray because I guess you want to loop on it with ngFor
    // .do(sum => console.log('sum:', sum)) // just for debug
    // .subscribe(d => MergedProd = d);
  }

  addRowForTransaction(index) {
    try {
      if (index === -1) {
        // this.TrnMainObj.TrntranList[0].ROWMODE =  "new";
        this.AddNewTrnTranRow(index);
        return;
      }

      if (this.TrnMainObj.TrntranList[index + 1]) return; // prevent to add row if selected at the item at somewhere except last

      let rm = this.TrnMainObj.TrntranList[index].ROWMODE;
      if (rm === 'new') {
        this.TrnMainObj.TrntranList[index].ROWMODE = 'new';
        this.AddNewTrnTranRow(index);
      } else if (rm === 'edit') {
        this.TrnMainObj.TrntranList[index].ROWMODE = 'edit';
      }
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  AddNewTrnTranRow(index) {
    try {
      if (!this.TrnMainObj.TrntranList) {
        this.TrnMainObj.TrntranList = [];
      }

      let currentObj = this.TrnMainObj.TrntranList[index];

      if (this.TrnMainObj.TrntranList[index + 1]) {
        return;
      }

      if (
        index !== -1 &&
        (!currentObj.AccountItem ||
          currentObj.AccountItem.ACID === undefined ||
          currentObj.AccountItem.ACID === '')
      ) {
        // this.alertService.info("Please Select A/C");
        alert('Please Select A/C');
        return;
      }

      if (
        index !== -1 &&
        ((currentObj.DRAMNT === 0 || currentObj.DRAMNT == null) &&
          (currentObj.CRAMNT === 0 || currentObj.CRAMNT == null))
      ) {
        alert('Debit Amount or Credit Amount is Required.');
        return;
      }

      let newRow = <Trntran>{};
      let newaclist: TAcList = <TAcList>{};
      newRow.AccountItem = newaclist;
      newRow.inputMode = true;
      newRow.editMode = true;

      newRow.ROWMODE = 'new';
      newRow.PartyDetails = [];
      this.TrnMainObj.TrntranList.push(newRow);
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  deleteAccountTrnRow(index) {
    try {
      if (this.TrnMainObj.TrntranList.length < 1) return;

      if (this.TrnMainObj.TrntranList.length === 1) {
        this.TrnMainObj.TrntranList.splice(index, 1);
        this.addRowForTransaction(-1);
        return;
      }
      let rm = this.TrnMainObj.TrntranList[index].ROWMODE;
      if (rm === 'new') {
        this.TrnMainObj.TrntranList.splice(index, 1);
      } else if (rm === 'save' || rm === 'edit') {
        this.TrnMainObj.TrntranList.splice(index, 1);
      }
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  calculateDrCrDifferences() {
    try {
      this.calculateCrDrTotal();
      this.differenceAmount = 0;
      if (
        this.TrnMainObj.VoucherType !== VoucherTypeEnum.Journal &&
        this.TrnMainObj.VoucherType !== VoucherTypeEnum.ContraVoucher
      ) {
        return;
      }
      let diffAmount: number = 0;
      this.TrnMainObj.TrntranList.forEach(tran => {
        diffAmount =
          diffAmount +
          ((tran.DRAMNT == null ? 0 : tran.DRAMNT) -
            (tran.CRAMNT == null ? 0 : tran.CRAMNT));
      });
      // this.differenceAmount = Math.abs(diffAmount);
      this.differenceAmount = diffAmount;
    } catch (ex) {
      this.alertService.error(ex);
    }
  }
  calculateCrDrTotal() {
    this.crTotal = 0;
    this.drTotal = 0;

    this.TrnMainObj.TrntranList.forEach(tran => {
      this.crTotal = this.crTotal + (tran.CRAMNT == null ? 0 : tran.CRAMNT);
      this.drTotal = this.drTotal + (tran.DRAMNT == null ? 0 : tran.DRAMNT);
    });
  }
  barcodeEnterCommand(event) {
    event.preventDefault();
    document.getElementById('itembarcode').blur();
    this.masterService
      .masterPostmethod('/getItemDetailsFromBarcode', {
        barcode: this.BarcodeFromScan
      })
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (res.message === 'multiItem') {
              this.ItemsListForMultiItemBarcode = JSON.parse(res.result);
              this.masterService.PlistTitle = 'itemList';
            } else {
              let item = JSON.parse(res.result);
              let index = this.TrnMainObj.ProdList.findIndex(
                x => x.MCODE === item.MCODE
              );

              if (index < 0) {
                this.addRow();
                this.assignValueToProdFromBarcode(
                  item,
                  this.TrnMainObj.ProdList.findIndex(x => x.MCODE == null)
                );
                document.getElementById('itembarcode').focus();
              } else {
                let alreadyExistingitem = this.TrnMainObj.ProdList[index];
                this.TrnMainObj.ProdList[index].Quantity += 1;
                this.RealQuantitySet(index, alreadyExistingitem.CONFACTOR);
                this.ReCalculateBillWithNormal();
                document.getElementById('itembarcode').focus();
              }
              this.BarcodeFromScan = '';
            }
          } else {
            this.alertService.error(res.result);
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }
  assignValueToProdFromBarcode(value, activerowIndex) {
    if (this.TrnMainObj.ProdList[activerowIndex] != null) {
      this.TrnMainObj.ProdList[activerowIndex].SELECTEDITEM = value;
      this.TrnMainObj.ProdList[activerowIndex].BC = value.BARCODE;
      this.TrnMainObj.ProdList[activerowIndex].PROMOTION = 0;
      this.TrnMainObj.ProdList[activerowIndex].BATCHSCHEME = null;
      this.TrnMainObj.ProdList[activerowIndex].ALLSCHEME = null;
      this.TrnMainObj.ProdList[activerowIndex].MRP = value.BatchObj.MRP;
      this.TrnMainObj.ProdList[activerowIndex].ISVAT = value.ISVAT;
      this.TrnMainObj.ProdList[activerowIndex].MENUCODE = value.MENUCODE;
      this.TrnMainObj.ProdList[activerowIndex].ITEMDESC = value.DESCA;
      this.TrnMainObj.ProdList[activerowIndex].MCODE = value.MCODE;
      this.TrnMainObj.ProdList[activerowIndex].GSTRATE_ONLYFORSHOWING =
        this.nullToZeroConverter(this.setting.appSetting.VATRate);
      this.TrnMainObj.ProdList[activerowIndex].GSTRATE = this.nullToZeroConverter(this.setting.appSetting.VATRate);
      this.TrnMainObj.ProdList[activerowIndex].WEIGHT = value.GWEIGHT;
      this.TrnMainObj.ProdList[activerowIndex].Mcat = value.MCAT;
      this.TrnMainObj.ProdList[activerowIndex].Product = value.Product;

      this.AssignSellingPriceAndDiscounts(
        value.PClist,
        activerowIndex

      );
      this.assignBatchToActiveProdRow(value.BatchObj, activerowIndex);
      this.TrnMainObj.ProdList[activerowIndex].Quantity = 1;
      this.TrnMainObj.ProdList[
        activerowIndex
      ].ALTUNITObj = this.TrnMainObj.ProdList[
        activerowIndex
      ].Product.AlternateUnits.filter(x => x.ISDEFAULT === 1)[0];

      let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
      let rate2 = 0;
      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived
      ) {
        rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
      } else {
        rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
      }

      this.ReCalculateBillWithNormal();
    }
  }

  assignBatchToActiveProdRow(value, activerowIndex) {
    console.log("batchvalue assign batch to active prod row",value);

    if (this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice && this.activeurlpath!='self-billing' && (value.EXPDATE != null && value.EXPDATE != "")) {
    if(new Date(value.EXPDATE) < new Date(new Date().setDate(new Date().getDate() - 1)))
     {
        this.alertService.warning(
          "Expired Item Detected!"
        );
        return;
      }
    }
    this.TrnMainObj.ProdList[activerowIndex].BATCHID = value.BATCHID;
    this.TrnMainObj.ProdList[activerowIndex].BATCH = value.BATCH;
    this.TrnMainObj.ProdList[activerowIndex].UPC = value.UPC;
    this.TrnMainObj.ProdList[activerowIndex].PRATE = value.COSTPRICE;
    this.TrnMainObj.ProdList[activerowIndex].SELLRATEBEFORETAX = value.SELLRATEBEFORETAX;
    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
      this.TrnMainObj.ProdList[activerowIndex].PBATCH = value.BATCH;
    }
    
    // this.TrnMainObj.ProdList[activerowIndex].BC = value.BCODE;
    this.TrnMainObj.ProdList[activerowIndex].MFGDATE =
      value.MFGDATE == null ? '' : value.MFGDATE.toString().substring(0,10);
    this.TrnMainObj.ProdList[activerowIndex].EXPDATE =
      value.EXPDATE == null ? '' : value.EXPDATE.toString().substring(0,10);
    this.TrnMainObj.ProdList[activerowIndex].STOCK = value.STOCK;
    this.TrnMainObj.ProdList[activerowIndex].WAREHOUSE = value.WAREHOUSE;
    this.TrnMainObj.ProdList[activerowIndex].BATCHSCHEME = value.SCHEME;
    this.TrnMainObj.ProdList[activerowIndex].PRATE = value.COSTPRICE;


    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
      this.TrnMainObj.ProdList[activerowIndex].REPACKRATE = value.RATE;
      this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.RATE * 
      this.nullToZeroConverter(this.TrnMainObj.ProdList[activerowIndex].CONFACTOR);  
      let rate =  this.TrnMainObj.ProdList[activerowIndex].REPACKRATE * this.TrnMainObj.ProdList[activerowIndex].CONFACTOR;
      this.TrnMainObj.ProdList[activerowIndex].RATE = rate;
    }else{
      if(this.TrnMainObj.VoucherType == VoucherTypeEnum.PreDebitNote && this.TrnMainObj.DOFULLRETURN == 0){
        this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.COSTPRICE;  //For manual predn rate from costprice of batchpricemaster
      }else{
        this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.RATE;
      }
    }

    if (this.TrnMainObj.ProdList[activerowIndex].UNIT == this.TrnMainObj.ProdList[activerowIndex].BASEUNIT) {
      if(this.TrnMainObj.VoucherType == VoucherTypeEnum.PreDebitNote && this.TrnMainObj.DOFULLRETURN == 0){
          this.TrnMainObj.ProdList[activerowIndex].RATE = value.COSTPRICE; //For manual predn rate from costprice of batchpricemaster
      }else{
        this.TrnMainObj.ProdList[activerowIndex].RATE = value.RATE;
      }
      console.log("ra",value.RATE,this.TrnMainObj.ProdList[activerowIndex].RATE);
    } else {
      this.TrnMainObj.ProdList[activerowIndex].RATE = value.RATE * this.nullToZeroConverter(this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR);
    }

    console.log("prodlist",this.TrnMainObj.ProdList);
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType == VoucherTypeEnum.PerformaSalesInvoice){
      this.checkDuplicateProductWithBatchCode(activerowIndex); //@0716-duplicate_mcode_batch_validation
    }

    if(this.TrnMainObj.ProdList[activerowIndex].ALTERNATEQUANTIY!=null ){
      this.ReCalculateBillWithNormal();
    }
  }

  diffAmountItemForAccount: number = 0;
  diffAmountDrCrType: string = '';
  getAccountWiseTrnAmount(acid: string) {
    if (!acid || acid == null || acid === undefined) return;

    this.diffAmountItemForAccount = 0;
    this.diffAmountDrCrType = '';
    let companyId = this.authservice.getCurrentCompanyId();
    let requestType = 0;
    if (this.TrnMainObj.VoucherType === 22 || this.TrnMainObj.VoucherType === 23) {
      requestType = 1;
    }

    this.masterService
      .getAccountWiseTrnAmount(
        this.TrnMainObj.TRNDATE.toString(),
        companyId,
        acid,
        this.TrnMainObj.DIVISION
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            this.diffAmountDrCrType = res.result < 0 ? 'Cr' : 'Dr';
            this.diffAmountItemForAccount = Math.abs(res.result);
          } else {
            this.diffAmountItemForAccount = 0;
            this.diffAmountDrCrType = '';
          }
        },
        error => {
          this.diffAmountItemForAccount = 0;
          this.diffAmountDrCrType = '';
        }
      );
  }
  ReCalculateBillWithNormal() {
    try {
      console.log("CheckProd#",this.TrnMainObj.ProdList)
      // return;
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      this.TrnMainObj.TOTALSQTY = 0;
      this.TrnMainObj.TOTVALUE=0;//FOR  TOTAL AMOUNT WITH AMOUNT-PRIMARYDISCOUNT-SECONDARYDI
      this.TrnMainObj.TOTALPRIMARYDISCOUNT=0;
      this.TrnMainObj.TOTALSECONDARYDISCOUNT=0;
      this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT=0;
      // let rQty = 0;

      this.TrnMainObj.ProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
            if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack){
              let rate = prod.REPACKRATE * prod.CONFACTOR;
              prod.RATE = rate;
            }  
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          // prod.ALTRATE = this.nullToZeroConverter(prod.RATE)*this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
 
        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
          prod.ROUTQTY = prod.ALTERNATEQUANTIY * this.nullToZeroConverter(prod.CONFACTOR);
         // prod.ALTRATE = prod.RATE * this.nullToZeroConverter(prod.CONFACTOR);
          
        }

        

        if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DeliveryReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry ||
          this.TrnMainObj.AdditionalObj.RETURNTYPE === 'production' 
        ) {
          // .CONFACTOR *
          prod.REALRATE = prod.RATE;
          prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.REALQTY_IN = rQty;
          prod.RealQty = 0;
          prod.AltQty = 0;
        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Delivery ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PreDebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation ||
          this.TrnMainObj.VoucherType ===VoucherTypeEnum.BatchAdjustment||
          this.TrnMainObj.AdditionalObj.RETURNTYPE === 'consumption' 
        ) {
          // alert("DispatchOut")
          // .CONFACTOR *
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        }
        else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
          prod.REALRATE = prod.RATE;
          if (rQty > 0) {
            // .CONFACTOR *
            prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
            prod.Quantity =
              prod.REALQTY_IN = rQty;
            prod.RealQty = 0;
            prod.AltQty = 0;
          } else if (rQty < 0) {
            prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
            prod.Quantity =

              prod.RealQty = rQty;
            prod.REALQTY_IN = 0;
            prod.ALTQTY_IN = 0;
          }
        } else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
          /*
               none of the value are 0 here
               later while save the value it will checks StockSettlement and then return 0 there.
               */
          prod.ALTQTY_IN =
            prod.REALQTY_IN =
            prod.Quantity =
            prod.AltQty =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrderCancel
        ) {


        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoiceCancel
        ) {

        } else {
          alert('Vouchertype not found please contact admin.');
        }

        // prod.AMOUNT =0;

        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 1) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (prod.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 2) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 3) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }


        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);

        if (prod.Weighable === 'kg' || prod.Weighable === 'ltr') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT);
        }
        else if (prod.Weighable === 'gram' || prod.Weighable === 'ml') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT / 1000);
        }

        // primary discount used as Customer FIxed Discount
        // secondary discount as scheme
        // liquidation discount as contract discount
        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {

          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER' ) {
              prod.RATE = prod.ALTRATE;
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
              prod.DISCOUNT = prod.INDDISCOUNT = prod.AMOUNT * (prod.INDDISCOUNTRATE / 100);
            } else {
              prod.INDDISCOUNT = ((prod.RATE * rQty) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - ((rQty * this.nullToZeroConverter(prod.RATE) +
                  this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(prod.INDDISCOUNTRATE) / 100))
            }
          } else {
            if(prod.PrimaryDiscount!=null||prod.PrimaryDiscount>0 ||prod.SecondaryDiscount!=null||prod.SecondaryDiscount>0){
              console.log("S")
              prod.INDDISCOUNT = (prod.AMOUNT- this.nullToZeroConverter(prod.PrimaryDiscount)- this.nullToZeroConverter(prod.SecondaryDiscount)+ this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
            }
          //  else if(prod.SecondaryDiscount!=null||prod.SecondaryDiscount>0){
          //     prod.INDDISCOUNT = (prod.AMOUNT- this.nullToZeroConverter(prod.PrimaryDiscount) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
          //   }
            else{
              prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
            }
         
          }
        
        } else {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER') {
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
            } else {
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.INDDISCOUNT);
            }
          }

        }
        //commented  prod.PrimaryDiscount as it affected the primary discount from the free stock popup
        // prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
        //   0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        // console.log("SW",prod.SecondaryDiscount)
        prod.SecondaryDiscount = 0;
        if( this.TrnMainObj.VoucherType == VoucherTypeEnum.CreditNote &&this.TrnMainObj.DOMANUALSALESRETURN==1){
          if(this.TrnMainObj.AdditionalObj.BILLUNITS == 'EACH'){
            console.log("sas",this.TrnMainObj.AdditionalObj.BILLUNITS)
            prod.SecondaryDiscount= this.nullToZeroConverter(prod.ALTERNATEQUANTIY * prod.ProrataDis);
            console.log("s12",prod.ALTERNATEQUANTIY * prod.ProrataDis)
            console.log("sdw2",prod.ALTERNATEQUANTIY )
          }else{
            prod.SecondaryDiscount= this.nullToZeroConverter(prod.ALTERNATEQUANTIY * prod.ALTCONFACTOR * prod.ProrataDis);
          }
       
      }else{
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);
      }

      if( this.TrnMainObj.VoucherType == VoucherTypeEnum.CreditNote &&this.TrnMainObj.DOMANUALSALESRETURN==1){
        if(this.TrnMainObj.AdditionalObj.BILLUNITS == 'EACH'){
          console.log("sas",this.TrnMainObj.AdditionalObj.BILLUNITS)
          prod.PrimaryDiscount= this.nullToZeroConverter(prod.AMOUNT * (prod.PrimaryDiscountRate/100));
        }
        // else{
        //   prod.PrimaryDiscount= this.nullToZeroConverter(prod.ALTERNATEQUANTIY * prod.ALTCONFACTOR * prod.PrimaryDiscountRate);
        // }
     
    }
        // console.log("S",prod.SecondaryDiscount)
        // prod.LiquiditionDiscount = this.nullToZeroConverter(prod.BaseLiquiditionDiscount) * prod.Quantity;
        if (this.TrnMainObj.Mode !='VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) == 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }
        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else {
          // prod.FLATDISCOUNT =
          //   ((prod.AMOUNT - disExcludingFlatDiscount) *
          //     this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT)) /
          //   totalAmt;
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived){ 
            this.calculateNetRateInFamount();
          }

         
        }
        //   prod.FixedDiscount = prod.RATE*prod.RealQty*this.CustomerObjForDiscount.CUS_FIX_DISCOUNT_PER/100
        //   prod.RemoteDiscount = prod.RATE*prod.RealQty*this.CustomerObjForDiscount.CUS_Remote_Discount/100
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);
       
        // prod.RATE = (this.nullToZeroConverter(prod.RATE)* this.nullToZeroConverter(prod.ALTCONFACTOR))
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }

        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;
        // console.log("k",prod.SecondaryDiscount)
        // let vatamnt = (prod.AMOUNT - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        let vatamnt = 0;
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE' && this.TrnMainObj.VATBILL != 1) {
          vatamnt = (prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        }
        else 
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.VATBILL == 1)) ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER' || this.TrnMainObj.VATBILL == 1)) ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder && this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0) || //added by anubhav
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation && this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0)||     //added by anubhav
      this.TrnMainObj.VoucherType ===VoucherTypeEnum.BatchAdjustment

        ) {
          vatamnt = 0;
        }
        else {
          vatamnt = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);          
        }
       
        this.TrnMainObj.TOTVALUE +=
        this.nullToZeroConverter(prod.AMOUNT) -
          this.nullToZeroConverter(prod.PrimaryDiscount) -
          this.nullToZeroConverter(prod.SecondaryDiscount) 

        //flatdiscount amout calc from flat dis percent
//         if((this.TrnMainObj.VoucherType==VoucherTypeEnum.PerformaSalesInvoice ||this.TrnMainObj.VoucherType==VoucherTypeEnum.TaxInvoice)){
            
//           if(this.TrnMainObj.DCRATE!=0  && (this.TrnMainObj.FLATDCAMNT==0 ||(this.TrnMainObj.FLATDCAMNT>0 && this.disableFlatPer==false))){
//             //flat amount calc by percentage
//             this.TrnMainObj.TAXABLE=0;
//               this.TrnMainObj.FLATDCAMNT=0;
//               this.TrnMainObj.FLATDCAMNT=Number((this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)* (this.TrnMainObj.DCRATE/100)).toFixed(2))
//           this.TrnMainObj.TAXABLE=this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)-this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)
//             }
//           if(this.TrnMainObj.FLATDCAMNT!=0 && (this.TrnMainObj.DCRATE==0 || (this.TrnMainObj.DCRATE>0  && this.disableFlatAmnt==false))){
//  //flat percent by amount
//               this.TrnMainObj.DCRATE=0;
//               this.TrnMainObj.TAXABLE=0;
//              this.TrnMainObj.DCRATE=Number((((this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT))/(this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)))*100).toFixed(2))
//              this.TrnMainObj.TAXABLE=this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)-this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)
//           }
        
//         }
                  
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' ) ||
              (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          if (prod.ISVAT == 1) {
            prod.TAXABLE = prod.AMOUNT-prod.PrimaryDiscount-prod.SecondaryDiscount;
            prod.NONTAXABLE = 0;
          } else if (prod.ISVAT == 0) {
            prod.TAXABLE = 0;
            prod.NONTAXABLE = prod.AMOUNT-prod.PrimaryDiscount-prod.SecondaryDiscount;
          }
        } else {
          if (prod.ISVAT == 1 && vatamnt > 0) {
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  )&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.TAXABLE = this.nullToZeroConverter(prod.AMOUNT) - this.nullToZeroConverter(prod.PrimaryDiscount)-this.nullToZeroConverter(prod.SecondaryDiscount) + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              
              prod.TAXABLE = (prod.AMOUNT+ this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX);
              console.log(prod.TAXABLE)
            }

            prod.NONTAXABLE = 0;
            prod.VAT = vatamnt;
          } else {
            prod.TAXABLE = 0;
            prod.VAT = 0;
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType===VoucherTypeEnum.GoodsReceived)&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.NONTAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.NONTAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            }

            // prod.NONTAXABLE = prod.AMOUNT - prod.DISCOUNT+  this.nullToZeroConverter(prod.SERVICETAX);

          }
        }

        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE') ||
        (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          prod.NETAMOUNT = prod.AMOUNT - this.nullToZeroConverter(prod.INDDISCOUNT);
        } else {
          prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);
        }
        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && prod.PrimaryDiscount!=null){
          prod.VAT=0;
          prod.NETAMOUNT=0;
          prod.VAT=(prod.AMOUNT-prod.PrimaryDiscount-prod.SecondaryDiscount) *0.13;
          prod.NETAMOUNT=prod.AMOUNT+prod.VAT;   //@0721should be taxable instead of amount testing required 
         // prod.TAXABLE=(prod.AMOUNT-prod.PrimaryDiscount-prod.SecondaryDiscount)

                    }

        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote && (this.TrnMainObj.DOMANUALSALESRETURN ==0 && this.TrnMainObj.DOFULLRETURN==0) ){
          prod.SELLRATEBEFORETAX=prod.RATE;
          prod.CRATE=prod.RATE;
        }

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        // this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.NETAMNT += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALQTY += rQty;
        //for total amnt
        //this.TrnMainObj.ProdList.forEach(x => {
        
       // });
        if(prod.FREEQTY!=null){
          this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(prod.ALTERNATEQUANTIY)+this.nullToZeroConverter(prod.FREEQTY);
        }else{
          this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        }

     
       
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);

        console.log("S", this.TrnMainObj.FLATDCAMNT)
        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

     

        });
      this.TrnMainObj.NETWITHOUTROUNDOFF = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETAMNT)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETWITHOUTROUNDOFF - this.TrnMainObj.NETAMNT;

        if(this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice && this.activeurlpath=="add-sales-invoice" && this.userSetting.ALLOWCOUNTERSALES==1 && this.TrnMainObj.TRNMODE.toUpperCase() == 'CASH'){
          if((this.TrnMainObj.REFBILL!=null  && this.TrnMainObj.REFBILL.substring(0,2)=='PP' )||(this.TrnMainObj.REFORDBILL!=null && this.TrnMainObj.REFORDBILL.substring(0,2)=='SO')||(this.TrnMainObj.VCHRNO!=null && this.TrnMainObj.VCHRNO.substring(0,2)=='RE')){
         return;
          }
          // console.log("@@this.TrnMainObj.NETAMNT",this.TrnMainObj.NETAMNT)
          // if(this.TrnMainObj.NETAMNT > 9999){
          //   this.alertService.error("Net amount exceed 9,999!");
          //   return;
          // }

          this.masterService.getNetAmountCustomerWise(this.TrnMainObj.BILLTO,this.TrnMainObj.NETAMNT).subscribe(
            (res:any)=>{
               if(res.status== "ok"){
                if(res.result!=""){
                  this.alertService.info(res.result);
                }
               }else{
                 this.alertService.info(res.result.error);
               }
            },
            error =>{
              console.log("err",error);
              this.alertService.info(error);
            }
          )
   
    }} catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }



  ReverseReCalculateBillWithNormal() {
    try {
    
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      this.TrnMainObj.TOTALSQTY = 0;
      // let rQty = 0;

      this.TrnMainObj.ProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        
        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
            prod.ALTRATE = (this.nullToZeroConverter(prod.NETAMOUNT) / this.nullToZeroConverter(this.userSetting.VatConRate))/rQty;
            prod.RATE = prod.ALTRATE;
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.ALTRATE = (this.nullToZeroConverter(prod.NETAMOUNT) / this.nullToZeroConverter(this.userSetting.VatConRate))/rQty;          
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
        console.log("prod",prod);
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        }
      else {
          alert('Vouchertype not found please contact admin.');
        }


        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 1) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 2) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 3) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);

        if (prod.Weighable === 'kg' || prod.Weighable === 'ltr') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT);
        }
        else if (prod.Weighable === 'gram' || prod.Weighable === 'ml') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT / 1000);
        }

        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {

          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER' ) {
              prod.RATE = prod.ALTRATE;
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
              prod.DISCOUNT = prod.INDDISCOUNT = prod.AMOUNT * (prod.INDDISCOUNTRATE / 100);
            } else {
              prod.INDDISCOUNT = ((prod.RATE * rQty) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - ((rQty * this.nullToZeroConverter(prod.RATE) +
                  this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(prod.INDDISCOUNTRATE) / 100))
            }
          } else {
            prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
          }
        
        } else {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER') {
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
            } else {
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.INDDISCOUNT);
            }
          }

        }
        prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
          0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);        
        if (this.TrnMainObj.Mode !== 'VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) === 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }

        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else {
 
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived){ 
            this.calculateNetRateInFamount();
          }         
        }
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }

        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;
        // let vatamnt = (prod.AMOUNT - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        let vatamnt = 0;
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE' && this.TrnMainObj.VATBILL != 1) {
          vatamnt = (prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        }
        else 
        if (
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.VATBILL === 1))
        ) {
          vatamnt = 0;
        }
        else {
          vatamnt = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);          
        }
       
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' ) ||
              (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          if (prod.ISVAT == 1) {
            prod.TAXABLE = prod.AMOUNT;
            prod.NONTAXABLE = 0;
          } else if (prod.ISVAT == 0) {
            prod.TAXABLE = 0;
            prod.NONTAXABLE = prod.AMOUNT;
          }
        } else {
          if (prod.ISVAT == 1 && vatamnt > 0) {
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  )&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.TAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.TAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX);
            }


            prod.NONTAXABLE = 0;
            prod.VAT = vatamnt;
          } else {
            prod.TAXABLE = 0;
            prod.VAT = 0;
            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.NONTAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.NONTAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            }
          }
        }

        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE') ||
        (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          prod.NETAMOUNT = prod.AMOUNT - this.nullToZeroConverter(prod.INDDISCOUNT);
        } else {
          prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);;
        }

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALQTY += rQty;
        this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);
        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

      });

      this.TrnMainObj.NETAMNT = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETWITHOUTROUNDOFF)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETAMNT - this.TrnMainObj.NETWITHOUTROUNDOFF;

    } catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }

  getCustomerListRouteAndSalesmanWise(salesManId: any, route: any) {
    let data = { salesManId: salesManId, route: route };
    this.masterService.getCustomerListBasedUponRouteAndSalesman(data)
      .subscribe(
        (res) => {
          this.CustomerList = res;
          if (this.CustomerList.length === 0) {
            this.customerListInPopup = true;
          }
          else if (this.CustomerList.length !== 0) {
            this.customerListInPopup = false;
          }
        });
  }

  loadDataForTaxCancel(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoiceCancel;
          this.TrnMainObj.VoucherAbbName = 'SC';
          this.TrnMainObj.VoucherPrefix = 'SC';
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;


          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          this.getVoucherNumberForTaxCancel();

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }
  DOFULLRETURN: number = 0;
  // There is already DOFULLRETURN which was maped on TrnmainObj. 
  // But when Ref Loaded the data it will auto initilize
  loadDataForSalesReturn(VCHR = '', division = '', phiscalid = '', BARCODE = '', REFBILL = '',TAG = '') {
    this.loadingService.show('Getting Details, Please Wait...');
    console.log("TAG",TAG)
    this.masterService.LoadTransaction(VCHR, division, phiscalid, BARCODE,REFBILL,'',TAG).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.DOFULLRETURN = this.DOFULLRETURN;
          this.TrnMainObj.VoucherType = 15;
          this.TrnMainObj.VoucherAbbName = 'CN';
          this.TrnMainObj.VoucherPrefix = 'CN';
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          this.TrnMainObj.MWAREHOUSE = this.salesReturnWarehouse;
          this.TrnMainObj.AdditionalObj.WAREHOUSETYPE = this.warehousetypeforSalesReturn;
          this.TrnMainObj.CUS_Remote_Discount=this.TrnMainObj.AdditionalObj.TOTDiscountPercentage;



          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.getVoucherNumber();
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error.error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );


  }


  loadDataForSalesReturnArrpoval(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadSalesReturnForApproval(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }




  loadDataForStockTransferApproval(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadStockIssueDataForPurchaseReturn(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
          this.TrnMainObj.VoucherPrefix = 'DN';
          this.TrnMainObj.VoucherAbbName = 'DN';

          this.TrnMainObj.VCHRNO = this.TrnMainObj.REFBILL;

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].VCHRNO = this.TrnMainObj.REFBILL;
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForPurchaseFromPOORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
          this.TrnMainObj.VoucherPrefix = 'PI';
          this.TrnMainObj.VoucherAbbName = 'PI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].RowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForStockIssueReturn(VCHRNO, division, phiscalid,REFBILL,warehouse,towarehouse) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    console.log('towarehouse',warehouse,towarehouse);
    this.masterService.LoadTransactionForIssueReturn(VCHRNO, division, phiscalid,REFBILL,warehouse).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssueReturn;
          this.TrnMainObj.VoucherPrefix = 'IR';
          this.TrnMainObj.VoucherAbbName = 'IR';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.BILLTO = warehouse;
          this.TrnMainObj.BILLTOADD = towarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].RowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();

          if(this.TrnMainObj.STOCKMESSAGE){
            this.alertService.info(this.TrnMainObj.STOCKMESSAGE);
          }


          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }



  loadDataForGRNFromPOORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.GoodsReceived;
          this.TrnMainObj.VoucherPrefix = 'GR';
          this.TrnMainObj.VoucherAbbName = 'GR';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
            this.TrnMainObj.ProdList[i].TEMP_ALTRATE = this.TrnMainObj.ProdList[i].ALTRATE;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }



  loadDataForImportPurchaseFromIMPORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
          this.TrnMainObj.VoucherPrefix = 'PI';
          this.TrnMainObj.VoucherAbbName = 'PI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.CNDN_MODE  = 10;
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          this.TrnMainObj.TRNAC = null;
          this.TrnMainObj.TRNACName = null;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          this.TrnMainObj.AdditionalObj.tag ='IMPORT_PURCHASE';
          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForSalesInoviceFromSO(VCHRNO, division, phiscalid,REFBILL,warehouse,vatno) {
    // console.log("loadDataForSalesInoviceFromSO")
    this.disableAllFieldinPurchase=true;
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL

    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL,undefined,undefined,warehouse).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoice;
          this.TrnMainObj.VoucherPrefix = 'TI';
          this.TrnMainObj.VoucherAbbName = 'TI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.LOADFULLFROMQT =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL =tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          this.TrnMainObj.PhiscalID = this.masterService.PhiscalObj.PhiscalID;
          this.TrnMainObj.CUS_Remote_Discount=this.TrnMainObj.AdditionalObj.TOTDiscountPercentage;
          this.TrnMainObj.BILLTOTEL=vatno;

          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            console.log(this.TrnMainObj.ProdList[i].EXPDATE)
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
            this.TrnMainObj.ProdList[i].ISVAT = 1;
         
            console.log("loadDataForSalesInoviceFromSO", this.TrnMainObj.ProdList[i].RATE, this.userSetting.VatConRate);
            // this.TrnMainObj.ProdList[i].ALTRATE = this.TrnMainObj.ProdList[i].ALTRATE2;
            if(this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0 && this.userSetting.ISDMSMASTER == 0){
              let tempRate = this.TrnMainObj.ProdList[i].RATE
              this.TrnMainObj.ProdList[i].RATE = tempRate / this.userSetting.VatConRate;
              this.TrnMainObj.ProdList[i].ALTRATE = tempRate / this.userSetting.VatConRate;
            }
           
          }
         
          //for quantity split stock wise from stocksummary 
          // var result = this.TrnMainObj.ProdList.filter((elem1, i) => this.TrnMainObj.ProdList.some((elem2, j) => (elem2.Quantity == elem1.Quantity &&  elem2.MCODE == elem1.MCODE)  && j !== i));
          // console.log("@@result",result)
          //  if(result.length>0){
          //   result.forEach(x=>{
          //     result.forEach(y=>{
          //       if(x.MCODE == y.MCODE){
          //         let index = this.TrnMainObj.ProdList.findIndex(z=>z.MCODE == x.MCODE);
          //         console.log("@@index",index)
          //         this.TrnMainObj.ProdList[index].ALTERNATEQUANTIY=x.Quantity-x.ALTERNATEQUANTIY;
          //       }
          //     })
          //   })
          // }
          //quantity split stock wise from stocksummary - ends

          this.ReCalculateBillWithNormal();
          this.itemrowcount=0;
          //free stock calculation
          this.freeQtyCalculationForLoadPerforma_Sales_masterqtysummwala();

          //for ordered  stock currently not available information
          if(this.TrnMainObj?.STOCKMESSAGE){
            this.alertService.info(this.TrnMainObj.STOCKMESSAGE.split(", ").join('\n'));
          }
if(this.TrnMainObj?.ProdList.length==0){
  this.newRowAdd()
}
          this.TrnMainObj.Mode = 'NEW';
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }

  // private freeQtyCalculationForLoadPerforma_Sales() {
  //   if( this.DMSCOMPANY.toUpperCase()=="DABUR"){
  //   for (let i in this.TrnMainObj.ProdList) {
  //     if (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY != null) {
  //       this.freeList = [];
  //       this.itemrowcount++;
  //       this.masterService.freeStockCalculation(
  //         this.TrnMainObj.ProdList[i].MCODE,
  //         this.TrnMainObj.ProdList[i].BATCH,
  //         this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY,
  //         this.TrnMainObj.ProdList[i].ALTRATE,
  //         this.TrnMainObj.ProdList[i].AMOUNT,
  //         this.TrnMainObj.ProdList[i].ALTUNIT
  //       ).subscribe((data: any) => {
  //         if (data.status == "ok") {
  //           this.freeList = data.result;
  //           // console.log("@@freeList", this.freeList)
  //           if (this.freeList.length > 0) {
  //             // let freedata = this.freeList.filter(x=>x.mcode==this.TrnMainObj.ProdList[i].MCODE && x.batch==this.TrnMainObj.ProdList[i].BATCH);
  //             this.DblClickPopupFree(this.freeList[0], i);
  //             //Sort item mcode wise to show item & its free qty in next row                   
  //             if (this.itemrowcount == this.TrnMainObj.ProdList.length) {
  //               let sortedArray = _.sortBy(this.TrnMainObj.ProdList, 'MCODE');
  //               if(sortedArray){
  //               this.TrnMainObj.ProdList = sortedArray;

  //               let _freeqtylist = this.TrnMainObj.ProdList.filter(x => x.FREEQTY > 0);

  //               for (var a of _freeqtylist) {
  //                 let _findfreeqtyindex = this.TrnMainObj.ProdList.findIndex(x => x.FREEQTY > 0 && x.MCODE == a.MCODE && x.BATCH == a.BATCH);
  //                 if (_findfreeqtyindex >= 0) {
  //                   this.TrnMainObj.ProdList[_findfreeqtyindex].UNIT = 'Each';
  //                   this.focusAfterCalculation(_findfreeqtyindex);
  //                 }
  //               }
  //             }
  //           }

  //           }
  //         }
  //       });
  //     }
  //   }
  // }
  // }
  Qty:number = 0;
AMNT:number=0;
  public freeQtyCalculationForLoadPerforma_Sales_masterqtysummwala() { //0159-replace old freeQtyCalculationForLoadPerforma_Sales with this one, for master qty sum up to calc freestock
    if( this.DMSCOMPANY.toUpperCase()=="DABUR"){
        let voucher;
        if(this.TrnMainObj.VoucherPrefix=="CN"){
          voucher=this.TrnMainObj.REFBILL
        }else{
          voucher=this.TrnMainObj.VCHRNO
        }
      let free_qty = this.TrnMainObj.ProdList.filter(x=>x.FREEQTY>0); 
      if(free_qty && free_qty.length && free_qty.length>0){
        for(let i of free_qty){
          let _findindex=this.TrnMainObj.ProdList.findIndex(x=>x.FREEQTY==i.FREEQTY && x.MCODE==i.MCODE && x.PARENTBATCH==i.PARENTBATCH);
          if(_findindex && _findindex>=0){
            this.TrnMainObj.ProdList.splice(_findindex,1);
          }
        }
      }
      console.log("ek",this.TrnMainObj.ProdList)
      var final_prod_list=[];
      this.itemrowcount=this.TrnMainObj.ProdList.length;
      var samemcode_result = this.TrnMainObj.ProdList.filter((elem1, i) => this.TrnMainObj.ProdList.some((elem2, j) => elem2.MCODE === elem1.MCODE && j !== i));
      var samemcode_result2:any= this.masterService.groupBy(
        this.TrnMainObj.ProdList.filter(X=>X.MCODE && X.BATCH),
        function (x) {
          return [x.MCODE,x.ALTERNATEQUANTIY>0];
        }
      );
    
      var samemcode_result3=samemcode_result2.filter(x=>x.length>1)
   

      // samemcode_result2.splice(samemcode_result2.findIndex(x=>x.length>1),1)
      if(samemcode_result3.length>0){
        let acs1: TrnProd = <TrnProd>{
          MCODE: "", BATCH: "", ALTUNIT: "",
          ALTERNATEQUANTIY: 0, ALTRATE: 0, AMOUNT: 0
      }

      //********the qty with same mcode is calculated by filtering and listed on the list *******
      samemcode_result3.forEach(x => {
        this.Qty=0
        acs1= <TrnProd>{
          MCODE: "", BATCH: "", ALTUNIT: "",
          ALTERNATEQUANTIY: 0, ALTRATE: 0, AMOUNT: 0
      }
        x.forEach(y => {
          //console.log("Check",y,y.ALTERNATEQUANTIY)

            this.Qty +=  this.nullToZeroConverter(y.ALTERNATEQUANTIY)
            this.AMNT+=this.nullToZeroConverter(y.AMOUNT)
        })
              acs1.MCODE = x[0].MCODE,
              acs1.BATCH = x[0].BATCH,
              acs1.ALTUNIT = x[0].ALTUNIT,
              acs1.ALTRATE = x[0].ALTRATE,
              acs1.ALTERNATEQUANTIY = this.Qty,
              //acs1.AMOUNT = element.AMOUNT + acs1.AMOUNT
               

        //console.log("3",this.Qty,acs1)
        final_prod_list.push(acs1);
      })

//****the qty with single mcode is passed to list  filtering and combined with same mcode list *******
       samemcode_result2.forEach(e =>
        {
          if(e.length==1){
            final_prod_list.push(e[0])
          }
        });



      // var result2 = this.TrnMainObj.ProdList.filter((elem1) => samemcode_result.some((elem2) => elem2.MCODE != elem1.MCODE));
      // console.log("ukdj",result2)
      // if (result2.length > 0) {
      //   for (let i of  result2) {
      //       var acs2: TrnProd = <TrnProd>{
      //         MCODE: i.MCODE,
      //         BATCH:i.BATCH,
      //             ALTUNIT: i.ALTUNIT,
      //             ALTERNATEQUANTIY: i.ALTERNATEQUANTIY,
      //           ALTRATE: i.ALTRATE,
      //           AMOUNT: i.AMOUNT
      //       };
      //       final_prod_list.push(acs2);
      //     }
      // }
      console.log("jdsbh",final_prod_list)
    for (let i in final_prod_list) {
      if (final_prod_list[i].ALTERNATEQUANTIY != null) {
        this.freeList = [];
        // this.itemrowcount++;
        this.masterService.freeStockCalculation(
          final_prod_list[i].MCODE,
          final_prod_list[i].BATCH,
          final_prod_list[i].ALTERNATEQUANTIY,
          final_prod_list[i].ALTRATE,
          final_prod_list[i].AMOUNT,
          final_prod_list[i].ALTUNIT,
          this.TrnMainObj.VoucherPrefix,
          voucher
          
        ).subscribe((data: any) => {
          if (data.status == "ok") {
            this.freeList = data.result;
            if (this.freeList.length > 0) {

                     this.DblClickPopupFree(this.freeList, i); //replace after freestock is finalized
              // this.DblClickPopupFree(this.freeList[0], i);

              //Sort item mcode wise to show item & its free qty in next row   
              if (this.itemrowcount == this.TrnMainObj.ProdList.length) {
                let sortedArray = _.sortBy(this.TrnMainObj.ProdList, 'MCODE');
                if(sortedArray){
                this.TrnMainObj.ProdList = sortedArray;

                let _freeqtylist = this.TrnMainObj.ProdList.filter(x => x.FREEQTY > 0);

                for (var a of _freeqtylist) {
                  let _findfreeqtyindex = this.TrnMainObj.ProdList.findIndex(x => x.FREEQTY > 0 && x.MCODE == a.MCODE && x.BATCH == a.BATCH);
                  if (_findfreeqtyindex >= 0) {
                    this.TrnMainObj.ProdList[_findfreeqtyindex].UNIT = 'Each';
                    this.focusAfterCalculation(_findfreeqtyindex);
                  }
                }
              }
            }

            }
          }
        });
      }
    }
    }else{
      for (let i in this.TrnMainObj.ProdList) {
        if (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY != null) {
          this.freeList = [];
          // this.itemrowcount++;
          this.masterService.freeStockCalculation(
            this.TrnMainObj.ProdList[i].MCODE,
            this.TrnMainObj.ProdList[i].BATCH,
            this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY,
            this.TrnMainObj.ProdList[i].ALTRATE,
            this.TrnMainObj.ProdList[i].AMOUNT,
            this.TrnMainObj.ProdList[i].ALTUNIT,
            this.TrnMainObj.VoucherPrefix,
            voucher
          ).subscribe((data: any) => {
            if (data.status == "ok") {
              this.freeList = data.result;
              // console.log("@@freeList", this.freeList)
              if (this.freeList.length > 0) {
                // let freedata = this.freeList.filter(x=>x.mcode==this.TrnMainObj.ProdList[i].MCODE && x.batch==this.TrnMainObj.ProdList[i].BATCH);

                this.DblClickPopupFree(this.freeList, i); //replace after freestock is finalized
                // this.DblClickPopupFree(this.freeList[0], i);

                //Sort item mcode wise to show item & its free qty in next row   
                if (this.itemrowcount == this.TrnMainObj.ProdList.length) {
                  let sortedArray = _.sortBy(this.TrnMainObj.ProdList, 'MCODE');
                  if(sortedArray){
                  this.TrnMainObj.ProdList = sortedArray;

                  let _freeqtylist = this.TrnMainObj.ProdList.filter(x => x.FREEQTY > 0);
  
                  for (var a of _freeqtylist) {
                    let _findfreeqtyindex = this.TrnMainObj.ProdList.findIndex(x => x.FREEQTY > 0 && x.MCODE == a.MCODE && x.BATCH == a.BATCH);
                    if (_findfreeqtyindex >= 0) {
                      this.TrnMainObj.ProdList[_findfreeqtyindex].UNIT = 'Each';
                      this.focusAfterCalculation(_findfreeqtyindex);
                    }
                  }
                }
              }
  
              }
            }
          });
        }
      }
    }
  }
  }

  loadDataForPerfromaInoviceFromSO(VCHRNO, division, phiscalid,REFBILL,warehouse="",mode="",qty=0,mcode="",vatno="") {
    // console.log("@@mode",mode)
    // console.log("@@qty",qty)
    this.disableAllFieldinPurchase=true;
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL,undefined,undefined,warehouse).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          // console.log("@@",this.TrnMainObj)         
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.PerformaSalesInvoice;
          this.TrnMainObj.VoucherPrefix = 'PP';
          this.TrnMainObj.VoucherAbbName = 'PP';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.LOADFULLFROMQT =1;
          this.TrnMainObj.BILLTOTEL=vatno;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          this.TrnMainObj.PhiscalID = this.masterService.PhiscalObj.PhiscalID;
          this.TrnMainObj.CUS_Remote_Discount=this.TrnMainObj.AdditionalObj.TOTDiscountPercentage;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          if(mode!="fromSOpopup"){
            for (let i in this.TrnMainObj.ProdList) {
              this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
              this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
              this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
              this.TrnMainObj.ProdList[i].visible = false;
              this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
              this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
              this.TrnMainObj.ProdList[i].ISVAT = 1;
            }
          }
          this.masterService.onSoLoad=true;
          //for quantity split stock wise from stocksummary 
          // var result = this.TrnMainObj.ProdList.filter((elem1, i) => this.TrnMainObj.ProdList.some((elem2, j) => (elem2.Quantity == elem1.Quantity &&  elem2.MCODE == elem1.MCODE)  && j !== i));
          // console.log("@@result",result)
          //  if(result.length>0){
          //   result.forEach(x=>{
          //     result.forEach(y=>{
          //       if(x.MCODE == y.MCODE){
          //         let index = this.TrnMainObj.ProdList.findIndex(z=>z.MCODE == x.MCODE);
          //         console.log("@@index",index)
          //         this.TrnMainObj.ProdList[index].ALTERNATEQUANTIY=x.Quantity-x.ALTERNATEQUANTIY;
          //       }
          //     })
          //   })
          // }
          //quantity split stock wise from stocksummary - ends
          
            if(mode=="fromSOpopup"){
              var _findindex = this.TrnMainObj.ProdList.findIndex(x=>x.MCODE==mcode && x.ALTERNATEQUANTIY==qty);
              // console.log("@@_findindex",_findindex)
              if(_findindex<0){
                var _findindex = this.TrnMainObj.ProdList.findIndex(x=>x.MCODE==mcode);
              }
              // console.log("@@_ProdListfindindex",this.TrnMainObj.ProdList[_findindex]);
              if(_findindex>=0){
                let product_data=this.TrnMainObj.ProdList[_findindex];
                this.TrnMainObj.ProdList=[];
                this.TrnMainObj.ProdList[0]=product_data;
                this.TrnMainObj.ProdList[0].MFGDATE = ((this.TrnMainObj.ProdList[0].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[0].MFGDATE.toString().substring(0, 7));
                this.TrnMainObj.ProdList[0].EXPDATE = ((this.TrnMainObj.ProdList[0].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[0].EXPDATE.toString().substring(0, 7));
                this.TrnMainObj.ProdList[0].BaseSecondaryDiscount = this.TrnMainObj.ProdList[0].SecondaryDiscount;
                this.TrnMainObj.ProdList[0].visible = false;
                this.TrnMainObj.ProdList[0].rowMode = this.buttonMode;
                this.TrnMainObj.ProdList[0].POSTOCK = this.TrnMainObj.ProdList[0].ALTERNATEQUANTIY;
                this.TrnMainObj.ProdList[0].ISVAT = 1;
                // console.log("@@ProdList_0",this.TrnMainObj.ProdList[0])
              }
            }
            this.itemrowcount=0;
            this.freeQtyCalculationForLoadPerforma_Sales_masterqtysummwala();

          //for ordered  stock currently not available information
          if(this.TrnMainObj?.STOCKMESSAGE){
              this.alertService.info(this.TrnMainObj.STOCKMESSAGE.split(", ").join('\n'));
            }
            if(this.TrnMainObj?.ProdList.length==0){
              this.newRowAdd();
            }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }

newRowAdd(){
    let newRow = <TrnProd>{};
    newRow.inputMode = true;
    newRow.MENUCODE = null;
    newRow.ITEMDESC = null;
    newRow.RATE = null;
    newRow.NCRATE = null;
    newRow.AMOUNT = null;
    newRow.INDDISCOUNTRATE = null;
    newRow.INDDISCOUNT = null;
    newRow.DISCOUNT = null;
    newRow.VAT = null;
    newRow.NETAMOUNT = null;
    newRow.ITEMTYPE = null;
    newRow.RECEIVEDTYPE = null;
    newRow.WAREHOUSE = null;
    newRow.BC = null;
    newRow.ALTERNATEQUANTIY = null;
    newRow.PRATE =0;
    this.TrnMainObj.ProdList.push(newRow)
}
  loadDataForSalesOrderFromQuotation(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.SalesOrder;
          this.TrnMainObj.VoucherPrefix = 'SO';
          this.TrnMainObj.VoucherAbbName = 'SO';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }




  loadDataForPurchaseReturn(VCHRNO, division, phiscalid, undefined, REFBILL) {
    console.log('load data for purchase reutrn', VCHRNO,division,phiscalid,undefined,REFBILL);

    this.loadingService.show('Getting Details, Please Wait...');
    console.log(VCHRNO)
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          console.log('mainObjectData1', this.TrnMainObj);
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
          this.TrnMainObj.VoucherPrefix = 'DN';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN = 1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            //this.setAltunitDropDownForView(i);

            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].Quantity = 0;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
          }



          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }
  loadDataForPrePurchaseReturn(VCHRNO, division, phiscalid, mode, REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    console.log(VCHRNO)
    // this.TrnMainObj.Mode = 'FORDEBITNOTE';
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined,undefined, REFBILL,'FORDEBITNOTE').subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          console.log('mainObjectData2', data['result']);
          this.TrnMainObj = data['result'];
          console.log(this,this.TrnMainObj)
           this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.PreDebitNote;
          this.TrnMainObj.VoucherPrefix = 'DD';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL=this.TrnMainObj.REFBILL;
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          
          // this.TrnMainObj.DOFULLRETURN = 1; //let Do Full Return checkbox value not change after loading PI
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.GROSSAMNT=0;
          this.TrnMainObj.CHALANNOPREFIX = null;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            //this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].Quantity = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].VAT = this.TrnMainObj.ProdList[i].TAXABLE*0.13;
            this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY);
            this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(this.TrnMainObj.ProdList[i].AMOUNT);
            let realqty_in=this.TrnMainObj.ProdList[i].REALQTY_IN; //in DD realqty30 realtqty_in 0 in MainWarehouse
            let realqty=this.TrnMainObj.ProdList[i].RealQty;
            this.TrnMainObj.ProdList[i].REALQTY_IN = realqty;
            this.TrnMainObj.ProdList[i].RealQty = realqty_in;
            this.TrnMainObj.ProdList[i].DISCOUNT=this.nullToZeroConverter(this.TrnMainObj.ProdList[i].PrimaryDiscount)+this.nullToZeroConverter(this.TrnMainObj.ProdList[i].SecondaryDiscount)
          }
          if(this.TrnMainObj.STOCKMESSAGE!=null){
this.alertService.error(this.TrnMainObj.STOCKMESSAGE)
          }


          //this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }

  prepareProductDetails() {
    let productDetails = [];
    let i = 0;
    for (let item of this.TrnMainObj.ProdList) {
      let prodDetailObj = <any>{};
      prodDetailObj.color = '';
      prodDetailObj.quantity = item.BASEQUANTITY;
      prodDetailObj.IEMI1 = item.BATCH;
      prodDetailObj.IEMI2 = '';
      //  this.TrnMainObj.ProdList.ProductDetails.
      this.TrnMainObj.ProdList[i].ProductDetails.push(prodDetailObj);
      i++;
    }
  }



  calculateRate(i) {
    let eachRate = this.TrnMainObj.ProdList[i].RATE;
    let conFactor = this.TrnMainObj.ProdList[i].ALTERNATEUNIT;

    if (this.TrnMainObj.ProdList[i].UNIT === 'EACH') {
      return eachRate;
    } else {

    }
  }

  transformDatetoMMddYY(date) {
    return moment(date).format('MM/DD/YYYY')
  }

  reverseEntry(voucherType:VoucherTypeEnum){
    this.TrnMainObj.guid = uuid();
    if(voucherType == VoucherTypeEnum.Purchase){
     // this.TrnMainObj.VoucherType = VoucherTypeEnum.ReversePurchase;
     // this.TrnMainObj.VoucherPrefix = "PX";
     // this.TrnMainObj.VoucherAbbName = "PX";
    }

   // this.getVoucherNumber();
  }


  subtractDates(date1, date2) {
    var dt1 = new Date(date1);
    var dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }
  deteProforma(vchrno) {
    this.masterService
      .masterPostmethod("/deteteProforma", {
        barcode: vchrno
      })
      .subscribe(
        res => {
          if (res.status == "ok") {
            this.alertService.success("Deleted Succesfully")

            this.initialFormLoad(this.TrnMainObj.VoucherType);

          } else {
            this.alertService.error(res.result);
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  checkBarcode(barCode: string, Warehouse: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn) {
      this.checkBarcodeForSalesReturn(barCode, this.TrnMainObj.REFBILL)
    }
    else {

      this.masterService.http.get(`${this.masterService.apiUrl}/checkDuplicateBarCodeDetail?barCode=${barCode}&warehouse=${Warehouse}`).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }
  }

  checkBarcodeForSalesReturn(barCode: string, Refbill: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    if (!Refbill) { this.alertService.warning("Please select Ref Bill No. first!"); return; }
    var bodydata = { barCode: barCode, Refbill: Refbill }
    this.masterService.masterPostmethod('/checkBarcodeForSalesReturn', bodydata).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  CheckIfSalesReturn(): boolean {
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote) {
      return true;
    } else {
      false
    }
  }
  ReturnStockReconcile(mcode): number {
    var totalStockEntered = 0
    for (let i of this.TrnMainObj.ProdList) {
      if (i.MCODE == mcode) {
        totalStockEntered += i.ALTERNATEQUANTIY;

      }

    }
    return totalStockEntered;
  }

  getVariantNameFromId = (variantID: string): string => {
    // console.log("getVariantNameFromID", variantID, this.masterService.variantmaster);
    // let variantdeail = this.masterService.variantmaster.filter(x => x.VARIANT == variantID);
    let variantdeail = this.masterService.variantmaster.filter(x => x.VARIANTNAME == variantID);

    // console.log("VDETAILS",variantdeail);
    if (variantdeail.length) {
      return variantdeail[0].VARIANTNAME;
    } else {
      return ""
    }
  }

  addConsumptionRow(){
    try{
      if(this.TrnMainObj.ConsumptionProdList == null){
        this.TrnMainObj.ConsumptionProdList = [];
      }
      this.newRowAdd()
      // let newRow = <TrnProd>{};
      // newRow.inputMode = true;
      // newRow.MENUCODE = null;
      // newRow.ITEMDESC = null;
      // newRow.RATE = null;
      // newRow.NCRATE = null;
      // newRow.AMOUNT = null;
      // newRow.INDDISCOUNTRATE = null;
      // newRow.INDDISCOUNT = null;
      // newRow.DISCOUNT = null;
      // newRow.VAT = null;
      // newRow.NETAMOUNT = null;
      // newRow.ITEMTYPE = null;
      // newRow.RECEIVEDTYPE = null;
      // newRow.WAREHOUSE = null;
      // newRow.BC = null;
      // newRow.ALTERNATEQUANTIY = null;
      // newRow.PRATE =0;

      // this.TrnMainObj.ConsumptionProdList.push(newRow);

      return true;
    } catch(ex){
      return false;
    }
  }

  getAllUnitsForConsumption(activerowIndex) {
    let alternateUnitList = [];
    let MappedalternateUnitList = [];
    this.masterService
      .masterGetmethod(
        '/getAllUnitsOfItem/' +
        this.TrnMainObj.ConsumptionProdList[activerowIndex].MCODE
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (
              this.TrnMainObj.ConsumptionProdList[activerowIndex].Product == null
            ) {
              this.TrnMainObj.ConsumptionProdList[activerowIndex].Product = <Product>{};
            }
            this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ConsumptionProdList[activerowIndex].MCODE;
            alternateUnitList = JSON.parse(res.result);
            for (let it of alternateUnitList) {
              let alternateUnitObj = <AlternateUnit>{};
              alternateUnitObj.ALTUNIT = it.UNIT;
              alternateUnitObj.PRATE_A = it.PRATE_A;
              alternateUnitObj.MCODE = it.MCODE;
              alternateUnitObj.ISDEFAULTPUNIT = it.ISDEFAULTPUNIT;
              alternateUnitObj.ISDEFAULT = it.ISDEFAULT;
              alternateUnitObj.CONFACTOR = this.nullToZeroConverter(it.CONFACTOR) == 0 ? 1 : it.CONFACTOR;
              alternateUnitObj.RATE_A = it.RATE_A;
              MappedalternateUnitList.push(alternateUnitObj);
            }

         
            this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits = MappedalternateUnitList;
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits[0];
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.ALTUNIT;
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.CONFACTOR;

          
              let isdefaultPIUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ISDEFAULTPUNIT == 1)[0];
              if (isdefaultPIUNIT != null) {
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNIT = isdefaultPIUNIT.ALTUNIT;
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTCONFACTOR = isdefaultPIUNIT.CONFACTOR;
               

              } else {
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.ALTUNIT;
              }
            
          } else {
          }
        },
        error => {
        }
      );

  }

  ReCalculateBillForConsumption() {
    try {
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      // let rQty = 0;

      this.TrnMainObj.ConsumptionProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
        //for consumption
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        
         
        // prod.AMOUNT =0;

  

        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);
        // primary discount used as Customer FIxed Discount
        // secondary discount as scheme
        // liquidation discount as contract discount
        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {
            prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
        } else {  }
        prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
          0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);
        // prod.LiquiditionDiscount = this.nullToZeroConverter(prod.BaseLiquiditionDiscount) * prod.Quantity;
        if (this.TrnMainObj.Mode !== 'VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) === 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }

        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ConsumptionProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else { }
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);

        // prod.RATE = (this.nullToZeroConverter(prod.RATE)* this.nullToZeroConverter(prod.ALTCONFACTOR))
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }
        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;


       
       prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);;
   

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALQTY += rQty;
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);

        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

      });

      this.TrnMainObj.NETAMNT = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETWITHOUTROUNDOFF)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETAMNT - this.TrnMainObj.NETWITHOUTROUNDOFF;

    } catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }

  loadStocktransferlist(VCHRNO, DIVISION, PHISCALID,MODE){
    this.masterService.LoadTransaction(VCHRNO,DIVISION,PHISCALID,MODE).subscribe(
      data => {
          if (data['status'] == "ok") {
              const uuidV1 = require('uuid/v1');  
              console.log("@@data,data",data)
              this.TrnMainObj = data['result'];
              this.TrnMainObj.guid = uuidV1();                      
              this.TrnMainObj.Mode = 'NEW';     
              this.TrnMainObj.REFORDBILL = VCHRNO;
              this.TrnMainObj.BILLTOADD = this.TrnMainObj.SETTLEMENTMODE;
              if (this.TrnMainObj.AdditionalObj == null) {
                this.TrnMainObj.AdditionalObj = <any>{};
              }
 
 
             console.log("@@this.activeurlpath ",this.activeurlpath )
              if(this.activeurlpath == "stock-issue-approval"){
               console.log("@@VCHRNO",this.TrnMainObj.VCHRNO)
               this.TrnMainObj.VCHRNO= VCHRNO;
               this.TrnMainObj.REFORDBILL=VCHRNO;
               this.TrnMainObj.REFBILL=VCHRNO;
               this.TrnMainObj.VoucherAbbName = "IS";
               this.TrnMainObj.VoucherPrefix = "IS";
               this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssue;
              }
          }
      },

  );
  }

  preventNavigation() {
    if (!this.saveChanges) {
      this.preventNavigationService.preventNavigation(true);
    } else {
      this.preventNavigationService.preventNavigation(false);
    }
  }
  
  FreeStockNewRow(data) {
    console.log("freeNew",data)
    let tr: TrnProd = <TrnProd>{};
    tr.ITEMDESC = data.productname;
    tr.BATCH = data.batch;
    tr.MCODE = data.mcode;
    tr.FREEQTY = data.freestock;
    tr.MENUCODE = data.mcode;
    //tr.ALTUNIT = 'Each';
    tr.EXPDATE = data.expdate;
    tr.ALTUNIT=data.unit;
    tr.UNIT=data.unit;
    tr.UPC=data.upc;
    tr.PARENTBATCH=data.parentBatch;
    tr.BATCHID = data.batchid;
    tr.MFGDATE = data.mfgdate;
    tr.PrimarySchemeNo=data.schemecode;
    this.TrnMainObj.ProdList.push(tr);
    var lastindex = this.TrnMainObj.ProdList.length - 1;
    // this.focusAfterCalculation(lastindex);
    this.itemrowcount++;
  }

  DblClickPopupFree(freelist, index) {  // after freestock is finalized
    for(let i of freelist){
      if (i.freestock > 0 && i.freerate == 0) {
        this.FreeStockCondition(i, index);
      }
      else if (i.freestock == 0 && i.freerate > 0) {
        this.FreeStockRemoveIndex(index)
        this.FreeEqualZero(i, index);
      }
      else if (i.freestock > 0 && i.freerate > 0) {
        // console.log("@@DblClickPopupFree",i.freestock,i.freerate)
        this.FreeStockCondition(i, index);
        this.FreeEqualZero(i, index);
      }
    }       
  }

  FreeStockCondition(event, index) {
    // console.log("@@FreeStockCondition",event)
    if (this.TrnMainObj.ProdList[index + 1]?.FREEQTY > 0) {
      this.FreeStockExisted(event, index);
    } else {
      this.FreeStockNewRow(event)
    }
  }

  FreeStockExisted(data, index) {
    console.log("@@FreeStockExisted",data)
    let nextRow = index + 1;
    this.TrnMainObj.ProdList[nextRow].BATCH = data.batch;
    this.TrnMainObj.ProdList[nextRow].MENUCODE = data.mcode;
    this.TrnMainObj.ProdList[nextRow].ITEMDESC = data.productname;
    this.TrnMainObj.ProdList[nextRow].FREEQTY = data.freestock;
    this.TrnMainObj.ProdList[nextRow].EXPDATE = data.expdate;
    this.TrnMainObj.ProdList[nextRow].UNIT=data.unit;
    this.TrnMainObj.ProdList[nextRow].ALTUNIT=data.unit;
    this.TrnMainObj.ProdList[nextRow].UPC=data.upc;
    this.TrnMainObj.ProdList[nextRow].PARENTBATCH=data.parentBatch;
    this.TrnMainObj.ProdList[nextRow].BATCHID = data.batchid;
    this.TrnMainObj.ProdList[nextRow].MFGDATE = data.mfgdate;
    this.TrnMainObj.ProdList[nextRow].PrimarySchemeNo=data.schemecode;
    this.itemrowcount++;
    // this.focusAfterCalculation(nextRow);
  }

  FreeStockRemoveIndex(index) {
    if (this.TrnMainObj.ProdList[index + 1]?.FREEQTY > 0) {
      this.TrnMainObj.ProdList.splice(index + 1, 1);
    }
  }

  FreeEqualZero(data, index2) {
    // console.log("@@#FreeEqualZero-data.mcode",data.mcode)
    // console.log("@@%this.TrnMainObj.ProdList[index].MCODE",this.TrnMainObj.ProdList[index].MCODE)
    // console.log("@@#FreeEqualZero-data.batch",data.batch)
    // console.log("@@%this.TrnMainObj.ProdList[index].BATCH",this.TrnMainObj.ProdList[index].BATCH)
    if(data.freestock==0){
      let free_index=this.TrnMainObj.ProdList.findIndex(x=>x.MCODE==data.mcode && x.ITEMDESC==data.productname && x.PARENTBATCH==data.parentBatch && x.FREEQTY>=0 && x.ALTERNATEQUANTIY==null);
      if(free_index>=0){
        this.TrnMainObj.ProdList.splice(free_index,1)
      }
    }
    let master_item = this.TrnMainObj.ProdList.filter(x => x.MCODE == data.mcode && x.ITEMDESC == data.productname && x.BATCH == data.parentBatch && x.ALTERNATEQUANTIY > 0);
    if (master_item.length && master_item.length > 0) { //0159-uncomment for freestock calc from button click this is for master qty summed up rows primary disc calc
      let master_index = this.TrnMainObj.ProdList.findIndex(x => x.MCODE == data.mcode && x.ITEMDESC == data.productname && x.BATCH == data.parentBatch && x.ALTERNATEQUANTIY > 0);
      // console.log("@@master_index", master_index)
      if(master_index>=0){
        this.TrnMainObj.ProdList[master_index].PrimaryDiscountRate = this.TrnMainObj.ProdList[master_index].MCODE == data.mcode ? data.freerate : 0;
        this.TrnMainObj.ProdList[master_index].PrimaryDiscount = this.TrnMainObj.ProdList[master_index].MCODE == data.mcode && this.TrnMainObj.ProdList[master_index].PrimaryDiscountRate > 0 ? this.TrnMainObj.ProdList[master_index].AMOUNT * ((this.TrnMainObj.ProdList[master_index].PrimaryDiscountRate) / 100) : 0;
        this.TrnMainObj.ProdList[master_index].PrimarySchemeNo = this.TrnMainObj.ProdList[master_index].MCODE == data.mcode ? data.schemecode : "";
      }

      let master_list_diffbatch = this.TrnMainObj.ProdList.filter(x => x.MCODE == data.mcode && x.ITEMDESC == data.productname && x.BATCH != data.parentBatch && x.ALTERNATEQUANTIY > 0); //0159-uncomment for freestock calc from button click this is for master qty summed up rows primary disc calc
      // console.log("@@master_list_diffbatch", master_list_diffbatch)
      if (master_list_diffbatch.length && master_list_diffbatch.length > 0) {
        for (let i of master_list_diffbatch) {
          let master_index_diffbatch = this.TrnMainObj.ProdList.findIndex(x => x.MCODE == i.MCODE && x.ITEMDESC == i.ITEMDESC && x.BATCH == i.BATCH && x.ALTERNATEQUANTIY > 0);
          // console.log("@@master_index_diffbatch", master_index_diffbatch)
          if (master_index_diffbatch >= 0) {
            // console.log("@@[master_index_diffbatch].BATCH", this.TrnMainObj.ProdList[master_index_diffbatch].BATCH)
            this.TrnMainObj.ProdList[master_index_diffbatch].PrimaryDiscountRate = this.TrnMainObj.ProdList[master_index_diffbatch].MCODE == data.mcode ? data.freerate : 0;
            this.TrnMainObj.ProdList[master_index_diffbatch].PrimaryDiscount = this.TrnMainObj.ProdList[master_index_diffbatch].MCODE == data.mcode && this.TrnMainObj.ProdList[master_index_diffbatch].PrimaryDiscountRate > 0 ? this.TrnMainObj.ProdList[master_index_diffbatch].AMOUNT * ((this.TrnMainObj.ProdList[master_index_diffbatch].PrimaryDiscountRate) / 100) : 0;
            this.TrnMainObj.ProdList[master_index_diffbatch].PrimarySchemeNo = this.TrnMainObj.ProdList[master_index_diffbatch].MCODE == data.mcode ? data.schemecode : "";
          }
        }
      }
    }

    // this.TrnMainObj.ProdList[index].TAXABLE = (this.nullToZeroConverter(this.TrnMainObj.ProdList[index].AMOUNT)-this.nullToZeroConverter(this.TrnMainObj.ProdList[index].PrimaryDiscount));
    // this.TrnMainObj.ProdList[index].VAT = this.nullToZeroConverter(this.TrnMainObj.ProdList[index].TAXABLE)*0.13;
    // this.TrnMainObj.ProdList[index].NETAMOUNT = this.nullToZeroConverter(this.TrnMainObj.ProdList[index].TAXABLE)+this.nullToZeroConverter(this.TrnMainObj.ProdList[index].VAT);
    this.ReCalculateBillWithNormal();

  }

  focusAfterCalculation(index){
    let o = index ;
   if (this.TrnMainObj.ProdList[o]?.FREEQTY) {
     if (this.TrnMainObj.ProdList[o]?.FREEQTY != null && this.TrnMainObj.ProdList[o]?.FREEQTY >0) {
       setTimeout(() => {
         document
           .getElementById("freeQty" + o)
           .setAttribute("disabled", "disabled");
         document
           .getElementById("rate" + o)
           .setAttribute("disabled", "disabled");
         document
           .getElementById("itemDesc" + o)
           .setAttribute("disabled", "disabled");
         document
           .getElementById("alternateunit" + o)
           .setAttribute("disabled", "disabled");
         document
           .getElementById("batch" + o)
           .setAttribute("disabled", "disabled");
        //  document
        //    .getElementById("discount" + o)
        //    .setAttribute("disabled", "disabled");
         document
           .getElementById("alternateqty" + o)
           .setAttribute("disabled", "disabled");
        //  document
        //    .getElementById("discountAmt" + o)
        //    .setAttribute("disabled", "disabled");
        //  document
        //    .getElementById("netamount" + o)
        //    .setAttribute("disabled", "disabled");
           document
           .getElementById("MENUCODE" + o)
           .setAttribute("disabled", "disabled");
       }, 0);
     }
   }
}

checkDuplicateProductWithBatchCode(i) { //@0716-duplicate_mcode_batch_validation
    let duplicateProdandBatch = this.TrnMainObj.ProdList.filter(x => x.MCODE == this.TrnMainObj.ProdList[i].MCODE && x.BATCH == this.TrnMainObj.ProdList[i].BATCH && x.BATCHID == this.TrnMainObj.ProdList[i].BATCHID);
    if (duplicateProdandBatch != null) {
      if (duplicateProdandBatch.length > 1) {
        this.alertService.info("Duplicate Product With Batch is not allowed");
        this.Dup_ItemBatchValidation = 'DuplicateProduct';
        setTimeout(() => {
          document.getElementById("itemDesc" + i).focus(); 
        }, 1100);
        return;
      }else {
        this.Dup_ItemBatchValidation = '';
      }
    }
   
}
flatDiscRateCalculation(){
  if(this.TrnMainObj.FLATDCAMNT!=0 && (this.TrnMainObj.DCRATE==0||this.TrnMainObj.DCRATE==null || (this.TrnMainObj.DCRATE>0  && this.disableFlatAmnt==false))){
    //flat percent by amount
                this.TrnMainObj.DCRATE=0;
              this.TrnMainObj.TAXABLE=0;
             this.TrnMainObj.DCRATE=Number((((this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT))/(this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)))*100).toFixed(2));
             this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = this.TrnMainObj.DCRATE);
             this.ReCalculateBillWithNormal() ;
         this.TrnMainObj.TAXABLE=this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)-this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)  
        }
}
flatDiscAmountCalculation(){
            if(this.TrnMainObj.DCRATE!=0  && (this.TrnMainObj.FLATDCAMNT==0 ||this.TrnMainObj.FLATDCAMNT==null||(this.TrnMainObj.FLATDCAMNT>0 && this.disableFlatPer==false))){
              //flat amount calc by percentage
              this.TrnMainObj.FLATDCAMNT=0;
              this.TrnMainObj.TAXABLE=0;
                this.TrnMainObj.FLATDCAMNT=this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)* (this.TrnMainObj.DCRATE/100)
            
              this.TrnMainObj.TOTALFLATDISCOUNT = this.nullToZeroConverter( this.TrnMainObj.FLATDCAMNT );
   this.TrnMainObj.TAXABLE=this.nullToZeroConverter(this.TrnMainObj.TOTVALUE)-this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)
              }
}
getSelectedPerformaInvoice(voucherNo) {
  this.loadingService.show('Getting data, Please wait...');
  this.masterService.loadHoPerformaInvoice(voucherNo).subscribe(
    result => {
      this.loadingService.hide();
      if (result.status === 'ok') {
        this.TrnMainObj = JSON.parse(result.result); //Object.assign({}, this._trnMainService.TrnMainObj, result.result );
        console.log(this.TrnMainObj)
        this.TrnMainObj.Mode = 'NEW';
        for (
          let i = 0;
          i < this.TrnMainObj.ProdList.length;
          i++
        ) {
          this.TrnMainObj.ProdList[i].inputMode = false;
          // this._trnMainService.TrnMainObj.ProdList[i].Quantity = result.result.ProdList[i].Quantity;
        }
        console.log('firstparac3');

        this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
        this.TrnMainObj.TRNACName = this.TrnMainObj.BILLTO;
        this.TrnMainObj.REFBILL = voucherNo;
       this.TrnMainObj.GROSSAMNT=   this.TrnMainObj.TOTAMNT;
        // this._trnMainService.TrnMainObj.FLATDCAMNT=JSON.parse(result.result).FDCAMNT
        this.TrnMainObj.VoucherPrefix = 'TI';
        this.TrnMainObj.VoucherType = 14;
        this.pageHeading = 'Tax Invoice';
        this.TrnMainObj.TRNMODE = 'credit';
         this.TrnMainObj.CUS_Remote_Discount=this.TrnMainObj.AdditionalObj.TOTDiscountPercentage;
        this.getVoucherNumber();
        this.getCurrentDate();
        //this._trnMainService.ReCalculateBillWithNormal();
        console.log("s",this.TrnMainObj)
        this.disableOnPerFormaSelect = true;
        for (let i in this.TrnMainObj.ProdList) {
        if(this.TrnMainObj.ProdList[i].FREEQTY!=null){
          this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY)+this.nullToZeroConverter(this.TrnMainObj.ProdList[i].FREEQTY);
        }else{
          this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY);
        }
      }
      
    }
    },
    error => {
      this.loadingService.hide();
    }
  );
}

  checkIfNetAmountGreater() {
    if (this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice && this.activeurlpath == "add-sales-invoice"
      && this.userSetting.ALLOWCOUNTERSALES == 1 && this.TrnMainObj.TRNMODE.toUpperCase() == 'CASH') {
      if ((this.TrnMainObj.REFBILL != null && this.TrnMainObj.REFBILL.substring(0, 2) == 'PP') ||
        (this.TrnMainObj.REFORDBILL != null && this.TrnMainObj.REFORDBILL.substring(0, 2) == 'SO') ||
        (this.TrnMainObj.VCHRNO != null && this.TrnMainObj.VCHRNO.substring(0, 2) == 'RE')) {
        return;
      }
      if (this.TrnMainObj.NETAMNT > 9999) {
        this.alertService.error("Net amount exceed 9,999!");
        return false;
      }
    }
  }
  public toBSDate(adDate: string): string {
    if (adDate) {
      const bsDate = (window as any).ad2bs(adDate, "YYYY-MM-DD");
      if (bsDate)
        return bsDate.day.toString().padStart(2, '0') + '/' + bsDate.month.toString().padStart(2, '0') + '/' + bsDate.year ;
    }
  }

  public toADDate(bsDate): string {
    if (bsDate) {
      const adDate = (window as any).bs2ad(bsDate, "DD/MM/YYYY");
      if (adDate)
        return adDate.year + '-' + adDate.month.toString().padStart(2, '0') + '-' + adDate.day.toString().padStart(2, '0');
    }
  }
}





export interface ProdListMode {
  mode: string;
  selectedRow: TrnMain;
}

