import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConfirmationPopup } from './confirmation-popup.component';

@NgModule({
  declarations: [ConfirmationPopup],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [ConfirmationPopup],
})
export class ConfirmationPopupModule {
  static forRoot(): ModuleWithProviders<ConfirmationPopupModule> {
    return {
      ngModule: ConfirmationPopupModule    };
  }
 }
