import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickPartyMasterComponent } from './quick-party-master.component';
import { ConfirmationPopup } from "../confirmation-popup/confirmation-popup.component";
import { ConfirmationPopupModule } from "../confirmation-popup/confirmation-popup.module";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopupModule.forRoot()
  ],
  declarations: [QuickPartyMasterComponent],
  exports: [QuickPartyMasterComponent]
})
export class QuickPartyMasterModule {
  static forRoot(): ModuleWithProviders<QuickPartyMasterModule> {
    return {
      ngModule: QuickPartyMasterModule,
    };
  }
}
