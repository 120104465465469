import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadPurchaseOrderComponent } from './load-purchase-order.component';
import { ConfirmationPopupModule } from "../confirmation-popup/confirmation-popup.module";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopupModule.forRoot()
  ],
  declarations: [LoadPurchaseOrderComponent],
  exports: [LoadPurchaseOrderComponent]
})
export class LoadPurchaseOrderModule {
  static forRoot(): ModuleWithProviders<LoadPurchaseOrderModule> {
    return {
      ngModule: LoadPurchaseOrderModule,
    };
  }
}
