<style>
  td {
    border: 1px solid #e6e6e6;
  }

  input {
    padding: 8px;
  }

  .form-group {
    margin-bottom: 0rem;
  }

  .party_container {
    position: fixed !important;
    transform: translate(0px, -35px);
    background: #ffffff;
    z-index: 99999;
    width: 77%;
    top: 22.5%;
    left: 10%;
  }
</style>


<div *ngIf="isActive" class="party_container" role="document"
  style="max-width: 100%; max-height: 500px; overflow:hidden">
  <div class="modal-content" style="padding: 14px 16px 10px 16px;margin: 0;width: 77vw; height:60vh;">

    <div class="modal-header row">
      <div class="col-md-7">
        <h4 class="modal-title pull-right">PARTY MASTER</h4>
      </div>
      <div class="col-md-5">
        <!-- <button type="button " class="btn btn-info pull-right" (click)="resetPartyAccount()"
                        style="margin-right : 10px;width:80px; margin-top: 15px;">
                        Reset
                    </button> -->


        <button type="button" class="btn btn-info pull-right" style="width:80px;"
          (click)="cancelPartyPopup()">Cancel</button>


        <button type="button" (click)="savePartyMaster()" title="onSave" class="btn btn-info pull-right"
          style="margin-right : 10px;width:80px; margin-top: 15px;">
          Save
        </button>
      </div>
    </div>



    <div class="row">
      <div class="col-md-8">
        <div>
          <div class="form-group row" style="margin-left: 4px;">
            <div class="col-md-4">
              <label class="pull-right" style="font-weight:bold;text-align: right; margin-top: 24px;">PARTY
                GROUP:</label>
            </div>
            <div class="col-md-8" style="width: 65%;margin-top: 20px;">
              <select #partyGroup id="partygroup" class="form-control" [(ngModel)]="partymasterObj.ACID" style="padding: 0px 8px;">
                <option *ngFor="let i of PartyGroupPartyMaster" value="{{i.ACID}}">{{i.ACNAME}}</option>
              </select>

            </div>
          </div>

          <div class=" form-group row">
            <div class="col-md-4">
              <label class=" pull-right" style=" font-weight: bold;text-align: right; margin-top:4px;">PARTY
                NAME:</label>
            </div>
            <div class="col-md-8" style="width: 65%;margin-top: 4px;">
              <input type="text" class="form-control" style="color: black;" [(ngModel)]="partymasterObj.ACNAME">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="pull-right" style="margin-top: 4px;text-align: right; font-weight: bold;">ADDRESS:</label>
            </div>
            <div class="col-md-8" style="width:  65%; margin-top:4px;">
              <textarea class="form-control" rows="2" cols="50" style="width: 100%; height: 40px;"
                [(ngModel)]="partymasterObj.ADDRESS"></textarea>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-9" style="padding:0px;">

            <div class="form-group row">
              <div class="col-md-5" style="padding: 0px;">
                <label class="pull-right" style="margin-top: 7px;text-align: right; font-weight: bold;  ">VAT
                  NO:</label>
              </div>
              <div class="col-md-7" style="width: 54%;margin-top: 9px; padding-left: 29px;">
                <input type="text" (keypress)="keyPress($event)" class="form-control" style="color: black;"
                  [(ngModel)]="partymasterObj.VATNO" maxlength="9">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-5" style="padding: 0px;">
                <label class="pull-right" style="margin-top: 4px;text-align: right; font-weight: bold;">PARTY CAT:
                </label>
              </div>
              <div class="col-md-7" style="width: 54%;margin-top: 5px; padding-left: 29px;">
                <select class="form-control form-control-sm" [(ngModel)]="partymasterObj.GEO" style="padding: 0px 8px ">
                  <option *ngFor="let i of GeoList" value="{{i.OrgTypeCode}}">{{i.OrgTypeName}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-5" style="padding: 0px;">
                <label class="pull-right" style="margin-top: 4px;text-align: right; font-weight: bold;">AREA:</label>
              </div>
              <div class="col-md-7" style="width: 54%; margin-top: 4px;padding-left: 29px;">
                <input type="text" name="product" list="areaName" (change)="onChangeArea($event)" class="form-control"
                  [(ngModel)]="partymasterObj.CITY" />
                <datalist id="areaName">
                  <option *ngFor="let i of areaList" value="{{i.AREANAME}}">{{i.AREANAME}}</option>
                </datalist>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-5" style="padding: 0px;">
                <label class="pull-right" style="margin-top: 4px;text-align: right; font-weight: bold;">
                  CREDIT LIMIT:</label>
              </div>
              <div class="col-md-7" style="width: 54%; margin-top: 4px; padding-left: 30px;">
                <input type="text" class="form-control" style="color: black;" [(ngModel)]="partymasterObj.CRLIMIT">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-5" style="padding: 0px;">
                <label class="pull-right" style="margin-top: 4px;text-align: right; font-weight: bold;">CREDIT
                  DAYS:</label>
              </div>
              <div class="col-md-7" style="width: 54%; margin-top: 4px; padding-left: 30px;">
                <input type="text" class="form-control" style="color: black;" [(ngModel)]="partymasterObj.CRPERIOD">
              </div>
            </div>

            <div class="form-group row"
              *ngIf="masterService.userSetting.CREATE_CPROFILE_AS_MEMBER == 1 && masterService.isSalesPage ==  true">
              <div class="col-md-5" style="padding: 0px;">
              </div>
              <div class="col-md-7" style="width: 54%; margin-top: 4px; padding-left: 30px;">
                <input type="checkbox" class="form-control" id="createMember" style="height: 59%;width: 5%;"
                  [(ngModel)]="createMember" [disabled]="partymasterObj.PType == 'V'">
                <label class="form-control-label" style="width: 100%; margin-top: -12%; margin-left: 7%;">Create
                  Customer A/C as Member Also</label>
              </div>
            </div>


          </div>

          <div class="col-md-3">

            <fieldset style="width: 125px; height: 100px; margin-top: 5px;">
              <legend style="font-size: 13px; font-weight: bold; text-align: center;">Party Type:</legend>
              <input type="radio" style="padding-right:15px; height:13px;" [(ngModel)]="partymasterObj.PType" value="C"
                [disabled]="masterService.isSalesPage ==  false"><label>&nbsp;Customer</label><br>
              <input type="radio" style="padding-right:15px; height:13px;" [(ngModel)]="partymasterObj.PType" value="V"
                [disabled]="masterService.isSalesPage ==  true"><label>&nbsp;Supplier</label>
            </fieldset>
          </div>
        </div>

      </div>

      <div class="col-md-4">
        <div class="form-group row">
          <label class="label" style="margin-top: 20px; font-weight: bold;">PARTY CODE:</label>
          <input type="text" class="form-control" style="color: black; width: 60%; margin-top: 20px;"
            [(ngModel)]="partymasterObj.ACCODE">
        </div>
        <div class="form-group row">
          <label class="label" style="margin-top: 20px; font-weight: bold;">Mobile No.:</label>
          <input type="text" class="form-control" style="color: black; width: 60%; margin-top: 20px;"
            [(ngModel)]="partymasterObj.MOBILE" (keypress)="keyPress($event)"  maxlength="10">
        </div>
      </div>


    </div>












  </div>
</div>

<confirmation-popup #ProceedConfirmation (okClicked)="onProceedConfirmationClicked($event)"></confirmation-popup>
