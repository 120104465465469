import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AlertService } from "@app/common/shared/alert/alert.service";
import { TransactionService } from "@app/common/Transaction Components/transaction.service";

@Component({
    selector: 'confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.css']
})

export class ConfirmationPopup implements OnInit{
    @Output('okClicked') okClicked = new EventEmitter();


    authOTP:any;
    isConfirmationVisible:boolean=false;
    DialogMessage: String;


    constructor( private alertService: AlertService, public _trnMainService: TransactionService){}

    ngOnInit(): void {
    }

  close(value: string | null) {
  this.okClicked.emit(false);
    this.isConfirmationVisible = false;
    this.DialogMessage = value;
  }
  
  show(value){ 
        this.isConfirmationVisible = true;
        this.DialogMessage = value;
     }

onSubmit(){
  this.okClicked.emit(true);
  this.isConfirmationVisible = false;

}

}